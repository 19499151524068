import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { BackButton, Card } from '../../../components';

import closeIcon from '../../../assets/close-red.svg';

import {
  Container,
  ContainerHeader,
  UploadContainer,
  UploadButton,
  FilesUploadList,
  FileUploaded,
  ButtonContainer,
  SendFilesButton
} from './styles';

export const UploadGo2StreamFiles = () => {
  // state to store 3 files
  const [filesList, setFilesList] = useState<File[]>([]);

  const history = useHistory();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // handle remove file from list
  const handleRemoveFile = (file: File) => {
    const newFilesList = filesList.filter((item) => item !== file);
    setFilesList(newFilesList);
  };

  const handleChangeUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      setFilesList([...filesList, files[0]]);
    }
  };

  return (
    <Container>
      <ContainerHeader>
        <BackButton onClick={() => history.goBack()} />
        <h1>Upload Go2Stream</h1>
      </ContainerHeader>
      <Card>
        <UploadContainer>
          <UploadButton onClick={handleClickUpload}>
            <i className="far fa-paperclip" />
            Upload Go2Stream
            <input
              type="file"
              accept=".xls, .xlsx, .csv, .ods, .xml"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleChangeUpload}
            />
          </UploadButton>
        </UploadContainer>
        <FilesUploadList>
          {filesList.map((file) => (
            <FileUploaded key={file.name}>
              {file.name}
              <img
                src={closeIcon}
                alt="Close"
                role="presentation"
                onClick={() => handleRemoveFile(file)}
              />
            </FileUploaded>
          ))}
        </FilesUploadList>
        <ButtonContainer>
          <SendFilesButton>Send files</SendFilesButton>
        </ButtonContainer>
      </Card>
    </Container>
  );
};
