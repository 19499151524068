import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1600px) {
    align-items: flex-start;
    margin-left: 5rem;
  }
`;

export const Title = styled.div`
  width: 90%;
  max-width: 1300px;
  height: 5%;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;

  h1 {
    font-size: 1.5rem;
  }

  p {
    color: var(--green);
    font-size: 0.9rem;
    text-decoration: underline;
    margin-left: 2rem;
    cursor: pointer;
    line-height: 23px;

    &:hover {
      font-weight: bold;
    }
  }

  @media (min-width: 1600px) {
    margin-top: 6rem;
  }

  @media (max-width: 1400px) {
    margin-top: 5rem;
  }

  @media (max-width: 900px) {
    margin-bottom: 0.54rem;
    margin-top: 4rem;
  }
`;

export const DivSettings = styled.div`
  width: 90%;
  max-width: 1300px;
  height: auto;
  max-height: 100%;
  padding: 2rem 2rem 2rem 4rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;
  background-color: var(--white);
  box-shadow: 0 6px 11px 0 var(--gray-750);
  border-radius: 0.5rem;
`;

export const DivInvoice = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
  margin-bottom: 7rem;

  p {
    color: var(--black);
    line-height: 23px;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }
`;

export const InputInvoice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55%;

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PickDate = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    p {
      margin-top: 1rem;
      margin-bottom: -0.3rem;
    }
  }
`;

export const DivTemplates = styled.div`
  display: flex;
  width: 90%;
  margin-bottom: 3rem;

  p {
    color: var(--black);
    line-height: 23px;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }

  @media (max-width: 1200px) {
    width: 100%;

    p {
      margin-bottom: 0;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

export const InputTemplates = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Templates = styled.div`
  width: 55%;
  margin-right: 13rem;

  @media (max-width: 1400px) {
    width: 60%;
    margin-right: 10rem;
  }

  @media (max-width: 1200px) {
    width: 45%;
    margin-right: 5rem;
  }

  @media (max-width: 900px) {
    margin-bottom: 2rem;
  }
`;

export const Purchase = styled.div`
  width: 20%;

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 800px) {
    width: 80%;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 30%;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 900px) {
    margin: 0;
    margin-top: -2.5rem;
    justify-content: flex-start;
  }
`;

export const DivMessage = styled.div`
  margin: 1rem auto;

  @media (max-width: 900px) {
    margin-top: 0rem;
  }
`;
