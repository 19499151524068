/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';

import { Divider, Spinner } from '../..';

import {
  Data
} from './styles';

import { StyledTableCell, StyledTableRow } from '../styles';

type InvoiceData = {
  id: string;
  description: string;
  quantity: string;
  unitPrice: number;
  tax: number;
  amountEur: number;
};

interface TableContentProps {
  contentPage: any;
}

const Head = () => {
  const headerTable = [
    'Description',
    'Quantity',
    'Unit Price',
    'Tax',
    'Amount EUR',
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {
            headerTable.map((header, index) => {
              return (
                <TableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="table-cell-head head"
                  align="center"
                  padding="none">
                  {header}
                </TableCell>
              )
            })
          }
        </TableRow>
        <TableRow>
          <TableCell className="table-cell-divider" colSpan={5} size="small">
            <div>
              <Divider />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableInvoiceDetails = (props: TableContentProps) => {
  const {
    contentPage,
  } = props;

  const [rows, setRows] = useState<InvoiceData[]>([]);

  useEffect(() => {
    setRows(contentPage);
  }, [contentPage]);

  return (
    <>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <Head />
          <>
            {rows ? (
              <TableBody>
                <>
                  {rows?.map((row) => {
                    const unitPrice = new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(row?.unitPrice);

                    const total = new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(row?.amountEur);

                    return (
                      <StyledTableRow
                        hover
                        tabIndex={-1}
                        key={Math.floor(Math.random() * 100)}
                      >
                        <StyledTableCell scope="row" padding="none" align="center">
                          <Data>
                            <p>{row?.description}</p>
                          </Data>
                        </StyledTableCell>
                        <StyledTableCell
                          scope="row"
                          padding="none"
                          align="center">
                          <Data>
                            <p>{row?.quantity}</p>
                          </Data>
                        </StyledTableCell>
                        <StyledTableCell scope="row" padding="none" align="center">
                          <Data>
                            <p>{unitPrice}</p>
                          </Data>
                        </StyledTableCell>
                        <StyledTableCell scope="row" padding="none" align="center">
                          <Data>
                            <p>{row?.tax}</p>
                          </Data>
                        </StyledTableCell>
                        <StyledTableCell scope="row" padding="none" align="center">
                          <Data>
                            <p>{total}</p>
                          </Data>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
                </>
              </TableBody>
            ) : (
              <Spinner />
            )}
          </>
        </Table>
      </TableContainer>
    </>
  );
};
