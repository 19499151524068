import { Dispatch, SetStateAction } from 'react';
import { api } from './api';
import { history } from './history';

export async function getInvoices(size?: number) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const hasSelectSize = size || 5;
  const response = await api.get(`/v1/invoice?size=${hasSelectSize}`, {
    validateStatus(status) {
      const verifyStatus = status;
      if (verifyStatus !== 200) {
        setTimeout(() => {
          history.push('/');
          sessionStorage.removeItem('app-token');
        }, 10000);
      }
      return status < 300;
    }
  });

  return response.data;
}

export async function getFilterInvoice(
  search?: string | '',
  size?: number,
  initial?: string | '',
  final?: string | '',
  page?: number,
  order?: string | '',
  orderBy?: string | '',
  setNoCompanyFound?: Dispatch<SetStateAction<boolean>>
) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  let hasInitial = '';
  let hasFinal = '';
  const hasSelectSize = size || 5;
  const hasFilter = search?.length !== 0 ? `&filter=${search}` : '';

  if (initial?.length !== 0 && initial) {
    const day = initial?.substring(0, 2);
    const month = initial?.substring(3, 5);
    const year = initial?.substring(6, 10);
    const formatDate = `${year}-${month}-${day}`;
    hasInitial = `&dateAfter=${formatDate}`;
  }

  if (final?.length !== 0 && final) {
    const day = final?.substring(0, 2);
    const month = final?.substring(3, 5);
    const year = final?.substring(6, 10);
    const formatDate = `${year}-${month}-${day}`;
    hasFinal = `&dateBefore=${formatDate}`;
  }

  const response = await api.get(
    `/v1/invoice?page=${page}${hasFilter}&size=${hasSelectSize}${hasInitial}${hasFinal}&sort=${orderBy}%3A${order}`,
    {
      validateStatus(status) {
        const verifyStatus = status;
        if (verifyStatus !== 200) {
          setTimeout(() => {
            history.push('/');
            sessionStorage.removeItem('app-token');
          }, 10000);
        }
        return status < 300;
      }
    }
  );

  if (response.data.totalElements === 0 && setNoCompanyFound) {
    setNoCompanyFound(true);
  }

  return response.data;
}

export async function getInvoiceById(id: string) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const response = await api.get(
    `/v1/invoice/invoice/{invoiceId}?invoiceId=${id}`
  );
  return response.data;
}
