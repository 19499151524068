import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { history } from '../../services/history';
import { authService } from '../../services/authService';

import {
  ValidationMessage,
  EmailInput,
  PasswordInput,
  Button,
  Spinner
} from '../../components';

import {
  Container,
  Artboard,
  Title,
  ForgotPassword,
  LoginHelper,
  Form,
  DivLogin,
  DivButton,
  DivLoginMessage
} from './styles';

import logo from '../../assets/logo.png';
import background from '../../assets/background.jpg';

export const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean | undefined>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean | undefined>(
    false
  );
  const [loginError, setLoginError] = useState<boolean | undefined>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean | undefined>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmitLogin = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (email.length === 0 && password.length === 0) {
      setEmailError(true);
      setPasswordError(true);
      return setLoading(false);
    }
    if (email.length === 0) {
      setEmailError(true);
      return setLoading(false);
    }
    if (password.length === 0) {
      setPasswordError(true);
      return setLoading(false);
    }

    const response = await authService(
      email,
      password,
      setLoginError,
      setLoginSuccess
    );
    return response;
  };

  useEffect(() => {
    if (loginSuccess) {
      setLoading(false);
      history.push('/invoices');
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (loginError) {
      setLoading(false);
      setErrorMessage('Please, check your data and try again.');
    }
  }, [loginError]);

  return (
    <Container>
      <DivLogin>
        <>
          <img src={logo} alt="Fruit People" />
          <Title>Welcome!</Title>
        </>

        <Form onSubmit={handleSubmitLogin} noValidate>
          <EmailInput
            label="Username"
            name="email"
            hasIcon
            autoFocus
            onChange={(event) => setEmail(event.currentTarget.value)}
            onClick={() => {
              setLoginError(false);
              setEmailError(false);
            }}
            emailError={emailError}
          />

          <PasswordInput
            label="Password"
            name="password"
            hasIcon
            onChange={(event) => setPassword(event.currentTarget.value)}
            onClick={() => {
              setLoginError(false);
              setPasswordError(false);
            }}
            passwordError={passwordError}
          />

          <DivButton>
            {loading ? (
              <Spinner />
            ) : (
              <Button type="submit" active>
                Login
              </Button>
            )}
          </DivButton>

          {loginError && (
            <DivLoginMessage>
              <ValidationMessage error>{errorMessage}</ValidationMessage>
            </DivLoginMessage>
          )}
        </Form>
      </DivLogin>
      <Artboard>
        <img src={background} alt="Fruit People" />
      </Artboard>
    </Container>
  );
};
