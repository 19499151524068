/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination
} from '@mui/material';

import { history } from '../../../services/history';
import { useInvoiceSelected } from '../../../contexts';
import { getFilterInvoice, getInvoiceById } from '../../../services/invoices';
import { Divider, TablePaginationActions, Spinner, ValidationMessage } from '../..';

import sortIcon from '../../../assets/sortIcon.png';

import {
  SortIcon,
  DivPagination,
  Data,
  DivButton,
  DivMessage
} from './styles';

import { StyledTableCell, StyledTableRow } from '../styles';

interface TableLabelProps {
  from: any;
  to: any;
  count: any;
}

type Order = 'asc' | 'desc';

type Data = {
  id: string;
  company: string;
  vat: string;
  totalOrder: number;
  date: string;
  invoiceNumber: string;
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface TableContentProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  contentPage: any;
  filtered: any;
  initial: any;
  final: any;
  search: string;
  totalElements: number;
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
}

const Head = (props: EnhancedTableProps) => {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'company' && order}>
            <TableSortLabel
              active={orderBy === 'company'}
              direction={orderBy === 'company' ? order : 'asc'}
              onClick={createSortHandler('company')}>
              Company
              {orderBy === 'company' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            VAT
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Total inc VAT
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Invoice Number
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'date' && order}>
            <TableSortLabel
              active={orderBy === 'date'}
              direction={orderBy === 'date' ? order : 'asc'}
              onClick={createSortHandler('date')}>
              Invoice Date
              {orderBy === 'date' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

        </TableRow>
        <TableRow>
          <TableCell className="table-cell-divider" colSpan={5} size="small">
            <div>
              <Divider />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableInvoices = (props: TableContentProps) => {
  const { setInvoiceSelectedState } = useInvoiceSelected();
  let getInvoicesData;
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    contentPage,
    filtered,
    initial,
    final,
    search,
    totalElements,
    order,
    setOrder,
    orderBy,
    setOrderBy
  } = props;

  const [rows, setRows] = useState<Data[]>([]);
  const [selected] = useState<readonly string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleGetInvoicesPerPage = async () => {
    getInvoicesData = await getFilterInvoice(
      search,
      rowsPerPage,
      initial,
      final,
      page,
      order,
      orderBy
    );

    if (getInvoicesData) {
      setRows(getInvoicesData.content);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function defaultLabelDisplayedRows(label: TableLabelProps) {
    const { from, to, count } = label;
    return `Show ${from}-${to} of ${count !== -1 ? count : `more than ${to}`
      } results`;
  };

  const handleGetInvoiceByID = async (
    id: string,
    company: string,
  ) => {
    setLoading(true);
    setInvoiceSelectedState(company, id);

    try {
      await getInvoiceById(id);
      history.push(`/invoices/${id}`);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const handleChangeContent = () => {
    return (
      <>
        {rows ? (
          <TableBody>
            <>
              {rows?.map((row) => {
                const totalOrder = new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(row.totalOrder);

                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => {
                      handleGetInvoiceByID(
                        row.id,
                        row.company
                      );
                    }}
                  >

                    <StyledTableCell scope="row" padding="none" align="center">
                      <Data>
                        <p>{row.company}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell
                      scope="row"
                      padding="none"
                      align="center">
                      <Data>
                        <p>{row.vat}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <Data>
                        <p>
                          {totalOrder}
                        </p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <Data>
                        <p>{row.invoiceNumber}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <Data>
                        <p>{row.date}</p>
                      </Data>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </>
          </TableBody>
        ) : (
          <Spinner />
        )}
      </>
    );
  };

  useEffect(() => {
    if (filtered?.content.length > 0) {
      setRows(filtered?.content);
    }
  }, [filtered]);

  useEffect(() => {
    setRows(contentPage);
  }, [contentPage]);

  useEffect(() => {
    handleGetInvoicesPerPage();
  }, [page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    handleChangeContent();
  }, [rows]);

  return (
    <>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <Head
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={contentPage.length}
          />

          {handleChangeContent()}
        </Table>
      </TableContainer>

      {rows?.length !== undefined && (
        <DivPagination>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 20]}
            colSpan={3}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={defaultLabelDisplayedRows}
            ActionsComponent={TablePaginationActions}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </DivPagination>
      )}

      <DivButton>
        {loading && <Spinner />}
        {error && (
          <DivMessage>
            <ValidationMessage error>
              Invoice not found
            </ValidationMessage>
          </DivMessage>
        )}
      </DivButton>
    </>
  );
};
