import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from '../services/history';

import {
  Login,
  ResetPassword,
  UploadFile,
  Invoices,
  InvoiceDetails,
  Orders,
  OrdersDetails,
  ProcessList,
  UploadGo2StreamFiles,
  Settings,
  SettingsConfiguration
} from '../pages';
import { AppLayout } from '../components';
import { AuthRoute } from './auth.routes';

export const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route component={Login} exact path="/" />
        <Route component={ResetPassword} exact path="/reset-password" />
        <AppLayout>
          <AuthRoute component={Invoices} exact path="/invoices" />
          <AuthRoute component={InvoiceDetails} exact path="/invoices/:id" />
          <AuthRoute component={Orders} exact path="/orders" />
          <AuthRoute component={OrdersDetails} exact path="/orders/:id" />
          <AuthRoute
            component={ProcessList}
            exact
            path="/go2stream/processes"
          />
          <AuthRoute
            component={UploadGo2StreamFiles}
            exact
            path="/go2stream/upload"
          />
          <AuthRoute component={UploadFile} exact path="/upload" />
          <AuthRoute component={Settings} exact path="/settings" />
          <AuthRoute
            component={SettingsConfiguration}
            exact
            path="/settings/:id"
          />
        </AppLayout>
      </Switch>
    </Router>
  );
};
