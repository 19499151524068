import React, { InputHTMLAttributes } from 'react';

import { Container } from './styles';

export interface SearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  icon?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ icon, ...rest }) => {
  return (
    <Container hasIcon={!!icon}>
      {icon && <i className={`far ${icon}`} />}
      <input {...rest} />
    </Container>
  );
};
