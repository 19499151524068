import {
  styled as styles,
  TableCell,
  tableCellClasses,
  TableRow
} from '@mui/material';

export const StyledTableCell = styles(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    lineHeight: 1.85,
    letterSpacing: '0.17px',
    color: '#7A7A7F',
    height: '60px',
    padding: '0px 16px'
  }
}));

export const StyledTableRow = styles(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  },

  '&.row-deleted-item': {
    backgroundColor: theme.palette.grey[200]
  }
}));
