import styled from 'styled-components';

type ButtonProps = {
  active?: boolean;
};

export const Container = styled.button<ButtonProps>`
  width: 100%;

  background-color: var(--gray-600);
  border: none;
  padding: 1rem 0;
  border-radius: 0.5rem;
  margin-top: 2rem;

  font-size: 1.15rem;
  color: var(--white);
  font-weight: bold;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  i {
    margin-right: 1rem;
  }

  ${({ active }) =>
    active &&
    `
      background-color: var(--green);
    `}
`;
