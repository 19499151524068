import styled from 'styled-components';

export const SortIcon = styled.div`
  margin-left: 0.4rem;
`;

export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Data = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    color: var(--gray-700) !important;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
    margin-left: 0.5rem;
  }
`;

export const ContentRow = styled.div`
  p {
    color: var(--gray-700) !important;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: auto;
  margin-top: 1rem;
  width: 13rem;

  @media (max-width: 900px) {
    margin: 0;
    justify-content: flex-start;
  }
`;

export const DivMessage = styled.div`
  margin: 1rem auto;
`;
