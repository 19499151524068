import React from 'react';
import { Overlay, Container, Loading } from './styles';

export const Spinner = () => {
  return (
    <Overlay>
      <Container>
        <Loading />
      </Container>
    </Overlay>
  );
};
