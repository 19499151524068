import React, { useState, useEffect } from 'react';
import { getOrders, getFilterOrder } from '../../services/orders';
import {
  SearchInput,
  DateInput,
  TableOrders,
  Spinner,
  StatusModal,
  ButtonClear
} from '../../components';

import {
  Container,
  DivOrders,
  Title,
  DateInputContainer,
  DateContent,
  InvalideDate,
  DivFilters,
  TableContainer,
  DivDate,
  DivStatus,
  DivSearch,
  SelectStatus,
  Label,
  DivSelect,
  NotFound
} from './styles';

type Data = {
  company: string;
  date: string;
  login: number;
  orderId: number;
  product: string;
  total: number;
};

type Order = 'asc' | 'desc';

export const Orders: React.FC = () => {
  let filteredOrders: any;

  const [ordersData, setOrdersData] = useState<Data[]>();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('customer.company');
  const [ordersConfig, setOrdersConfig] = useState<any>({
    totalPages: 0,
    totalElements: 0
  });
  const [filteredData, setFilteredData] = useState<Data[]>();
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState<string>('');
  const [validateDate, setValidateDate] = useState(false);
  const [noCompanyFound, setNoCompanyFound] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [initialDate, setInitialDate] = useState<string | null>(null);
  const [finalDate, setFinalDate] = useState<string | null>(null);
  const [selectStatus, setSelectStatus] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const getOrdersData = async () => {
    const orders = await getOrders();
    if (orders) {
      setOrdersData(orders.content);
      setOrdersConfig({
        ...ordersConfig,
        totalPages: orders.totalPages,
        totalElements: orders.totalElements
      });
    }
  };

  const handleFilter = async (
    statusInvoice: string,
    searchQuery: string,
    rowsPerPageSelected: number,
    dateInitial: any,
    dateFinal: any,
    currentPage: number,
    typeOrder: Order,
    orderedBy: string
  ) => {
    setSearch(searchQuery);

    filteredOrders = await getFilterOrder(
      statusInvoice,
      searchQuery,
      rowsPerPageSelected,
      dateInitial,
      dateFinal,
      currentPage,
      typeOrder,
      orderedBy,
      setNoCompanyFound
    );

    if (filteredOrders) {
      setFilteredData(filteredOrders);
      setRowsPerPage(5);
      setOrdersConfig({
        ...ordersConfig,
        totalPages: filteredOrders.totalPages,
        totalElements: filteredOrders.totalElements
      });
    }
  };

  const handleFindFilter = () => {
    return (
      <>
        {ordersConfig?.totalPages === 0 && !!filteredData ? (
          <NotFound>
            <i className="fas fa-exclamation-triangle" />
            No company found
          </NotFound>
        ) : (
          <TableContainer>
            {ordersData ? (
              <TableOrders
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                contentPage={ordersData}
                filtered={filteredData}
                initial={initialDate}
                final={finalDate}
                search={search}
                status={status}
                totalElements={ordersConfig.totalElements}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            ) : (
              <Spinner />
            )}
          </TableContainer>
        )}
      </>
    );
  };

  useEffect(() => {
    handleFindFilter();
  }, [filteredData]);

  useEffect(() => {
    setNoCompanyFound(false);
    handleFilter(
      status,
      filter,
      rowsPerPage,
      initialDate,
      finalDate,
      page,
      order,
      orderBy
    );
  }, [filter]);

  useEffect(() => {
    setPage(0);
    let statusInvoice;

    if (selectStatus === 'Non Invoiced') {
      setStatus('WAITING');
      statusInvoice = 'WAITING';
    } else if (selectStatus === 'All') {
      setStatus('');
      statusInvoice = '';
    } else {
      setStatus(selectStatus.toLocaleUpperCase());
      statusInvoice = selectStatus.toLocaleUpperCase();
    }

    handleFilter(
      statusInvoice,
      filter,
      rowsPerPage,
      initialDate,
      finalDate,
      page,
      order,
      orderBy
    );
  }, [selectStatus]);

  useEffect(() => {
    if (noCompanyFound) {
      setTimeout(() => {
        setFilter('');
        setSelectStatus('');
        setInitialDate(null);
        setFinalDate(null);
      }, 5000);
    }
  }, [noCompanyFound]);

  useEffect(() => {
    if (validateDate) {
      setTimeout(() => {
        setFinalDate(null);
        setValidateDate(false);
      }, 1000);
    }
  }, [validateDate]);

  useEffect(() => {
    setNoCompanyFound(false);
    setPage(0);

    if (initialDate && finalDate) {
      const strData = initialDate;
      const partData = strData.split('/');
      const firtsDate = new Date(
        parseInt(partData[2], 10),
        parseInt(partData[1], 10) - 1,
        parseInt(partData[0], 10)
      );

      const strData2 = finalDate;
      const partData2 = strData2.split('/');
      const secondDate = new Date(
        parseInt(partData2[2], 10),
        parseInt(partData2[1], 10) - 1,
        parseInt(partData2[0], 10)
      );

      if (secondDate.getTime() < firtsDate.getTime()) {
        setValidateDate(true);
      } else {
        handleFilter(
          status,
          search,
          rowsPerPage,
          initialDate,
          finalDate,
          page,
          order,
          orderBy
        );
      }
    } else if (!initialDate && !finalDate) {
      setNoCompanyFound(false);
      handleFilter(
        status,
        search,
        rowsPerPage,
        undefined,
        undefined,
        page,
        order,
        orderBy
      );
    }
  }, [initialDate, finalDate]);

  useEffect(() => {
    getOrdersData();
    return setFilteredData(undefined);
  }, []);

  return (
    <Container>
      <Title>
        <h1>Orders</h1>
      </Title>
      <DivOrders>
        <DivFilters>
          <DivSearch>
            <SearchInput
              placeholder="Search"
              icon="fa-search"
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value);
                setPage(0);
              }}
            />
            <ButtonClear
              onClick={() => {
                setFilter('');
              }}>
              Clear
            </ButtonClear>
          </DivSearch>

          <DivSelect>
            <DivDate>
              <DateInputContainer>
                <DateContent>
                  <div>
                    <p>From</p>
                  </div>
                  <DateInput
                    setSelectDate={setInitialDate}
                    selectDate={initialDate}
                  />
                </DateContent>
                <DateContent>
                  <div>
                    <p>To</p>
                    {validateDate && <InvalideDate>Invalid Date!</InvalideDate>}
                  </div>
                  <DateInput
                    setSelectDate={setFinalDate}
                    selectDate={finalDate}
                  />
                </DateContent>
                <DateContent>
                  <ButtonClear
                    onClick={() => {
                      setInitialDate(null);
                      setFinalDate(null);
                    }}>
                    Clear
                  </ButtonClear>
                </DateContent>
              </DateInputContainer>
            </DivDate>
            <DivStatus>
              <p>Filter status</p>
              <SelectStatus onClick={() => setOpenModal(!openModal)}>
                <Label active={!!selectStatus}>
                  {selectStatus || 'Select'}
                </Label>
                <i className="fas fa-chevron-down" />
              </SelectStatus>
              {openModal && (
                <StatusModal
                  setOpenModal={setOpenModal}
                  setSelectStatus={setSelectStatus}
                />
              )}
            </DivStatus>
          </DivSelect>
        </DivFilters>

        {handleFindFilter()}
      </DivOrders>
    </Container>
  );
};
