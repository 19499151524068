import styled from 'styled-components';

type MessageProps = {
  success?: boolean;
  error?: boolean;
};

export const Container = styled.small<MessageProps>`
  font-size: 0.85rem;
  margin-top: 1rem;
  width: 70%;

  ${({ success }) =>
    success &&
    `
    color: var(--green);

    i {
      margin-right: 5px;
      background-color: var(--background-green);
      border-radius: 50%;
    }
  `}

  ${({ error }) =>
    error &&
    `
    color: var(--danger);

    i {
      margin-right: 5px;
      background-color: var(--background-red);
      border-radius: 50%;
    }
  `}
`;
