import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: relative;
`;

export const ModalContent = styled.div`
  width: 130px;
  max-width: 130px;
  height: auto;
  padding: 0.5rem 0.75em;
  margin-top: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 8;
  position: absolute;

  background-color: var(--white);
  color: var(--gray-650);
  border-radius: 0.75rem;
  box-shadow: 0 10px 11px 0 var(--shadow-20);
  border: 1px solid var(--gray-20);

  i {
    font-size: 10px;
    cursor: pointer;
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const DivOption = styled.div`
  height: 1.5rem;
  width: 95%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  color: var(--gray-600);
  font-weight: 700;
  padding-left: 0.5rem;

  &:hover {
    background-color: var(--gray-20);
    color: var(--black);
  }

  &:first-of-type {
    margin-top: 0.5rem;
  }
`;
