import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1600px) {
    align-items: flex-start;
    margin-left: 5rem;
  }
`;

export const Title = styled.div`
  width: 90%;
  max-width: 1300px;
  height: 5%;
  margin-bottom: 2rem;
  display: flex;

  span {
    font-size: 15px;
    font-weight: bold;
    color: var(--gray-650);
  }

  @media (min-width: 1600px) {
    margin-top: 6rem;
  }

  @media (max-width: 1400px) {
    margin-top: 5rem;
  }
`;

export const DivLeft = styled.div`
  display: flex;
  padding-right: 2rem;
  flex-direction: column;
  justify-content: space-between;
  height: 4rem;
  width: auto;

  h1 {
    font-size: 1.5rem;
  }
`;

export const DivRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 4rem;
  width: auto;

  p {
    color: var(--green);
    font-size: 0.9rem;
    text-decoration: underline;
    cursor: pointer;
    line-height: 23px;

    &:hover {
      font-weight: bold;
    }
  }
`;

export const DivInvoices = styled.div`
  width: 90%;
  max-width: 1300px;
  height: auto;
  max-height: 80%;
  padding: 1rem 2rem 0 2rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;
  background-color: var(--white);
  box-shadow: 0 6px 11px 0 var(--gray-750);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const TableContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem 1rem 0.5rem 1rem;
  overflow-y: auto;

  border-radius: 15px;
  background-color: var(--white);

  .MuiTableSortLabel-icon {
    display: none;
  }

  .head {
    border: 0;
    padding: 0;
    margin: 0;
  }

  .table-container {
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0.5rem;
      background: var(--gray-20);
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray-90);
    }
  }

  .table-cell-head {
    color: var(--gray-750) !important;
    font-weight: 600;
  }

  .table-cell-first {
    width: 50%;
    padding-left: 2rem;
  }

  .table-pagination {
    width: 100%;
    height: 2.8rem;
    overflow: hidden;
    color: var(--gray-600) !important;
    font-weight: 600;
    border: none !important;
    font-size: 0.75rem;
  }

  .table-pagination-content {
    border-radius: 1rem;
    background-color: var(--background-grey);
    margin-left: 65%;
    margin-right: 1.5rem;
    height: 2.5rem;

    @media (max-width: 1700px) {
      margin-left: 60%;
    }

    @media (max-width: 1290px) {
      margin-left: 50%;
    }

    @media (max-width: 920px) {
      margin-left: 40%;
    }

    @media (max-width: 690px) {
      margin-left: 0;
      margin-right: 100%;
    }
  }

  .table-cell-divider {
    padding: 0 !important;
    margin: 0 !important;
    border: 0;

    div {
      height: 3rem;
      display: flex;
      justify-content: stretch;
      align-items: flex-start;
      margin: -1rem 0;
    }
  }

  @media (max-width: 988px) {
    padding: 1.5rem 0.5rem 1rem 0.5rem;
    overflow-x: scroll;

    .table-cell-head {
      font-size: 11px !important;
    }
  }

  @media (max-width: 690px) {
    .MuiTablePagination-displayedRows {
      display: none !important;
    }

    .MuiTableCell-body {
      height: 75px !important;
    }
  }
`;

export const DivError = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  margin: 1rem auto;
  padding-left: 2rem;
`;
