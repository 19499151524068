import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1600px) {
    align-items: flex-start;
    margin-left: 5rem;
  }
`;

export const Title = styled.div`
  width: 90%;
  max-width: 1300px;
  height: 5%;
  margin-bottom: 1rem;

  h1 {
    font-size: 1.5rem;
  }

  @media (min-width: 1600px) {
    margin-top: 6rem;
  }

  @media (max-width: 1400px) {
    margin-top: 5rem;
  }

  @media (max-width: 900px) {
    margin-bottom: 0.54rem;
    margin-top: 4rem;
  }
`;

export const DivUpload = styled.div`
  width: 90%;
  max-width: 1300px;
  height: 20rem;
  padding: 4rem;
  margin-bottom: 10%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;
  background-color: var(--white);
  box-shadow: 0 6px 11px 0 var(--gray-750);
  border-radius: 0.5rem;
`;

export const DivButton = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  margin: 1rem auto 0 auto;
  flex-direction: column;

  @media (max-width: 988px) {
    width: 60%;
  }
`;

export const DivMessage = styled.div`
  margin: 1rem auto;
`;
