/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useState } from 'react';

type Order = {
  id: number;
  orderId: number;
  company: string;
  total: string;
}

type OrderSelectedContext = {
  selectedOrder:
  | Order
  | undefined;
  setOrderSelectedState(
    state:
      | Order
      | undefined
  ): void;
};

export const OrderSelectedContext = createContext<OrderSelectedContext>(
  {} as OrderSelectedContext
);

export const OrderSelectedContextProvider: React.FC = ({ children }) => {
  const [selectedOrder, setSelectedOrder] = useState<
    | Order
    | undefined
  >();

  const setOrderSelectedState = (state: any) => {
    setSelectedOrder(state);
  };

  return (
    <OrderSelectedContext.Provider
      value={{
        selectedOrder,
        setOrderSelectedState
      }}>
      {children}
    </OrderSelectedContext.Provider>
  );
};

export function useOrderSelected(): OrderSelectedContext {
  const context = useContext(OrderSelectedContext);
  return context;
}
