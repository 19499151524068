/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, SetStateAction } from 'react';

import { ModalContainer, ModalContent, Options, DivOption } from './styles';

interface ModalProps {
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  setSelectStatus: Dispatch<SetStateAction<string>>;
}

export const StatusModal: React.FC<ModalProps> = ({
  setOpenModal,
  setSelectStatus
}) => {
  const handleSelectStatus = (status: string) => {
    if (setOpenModal && setSelectStatus) {
      setOpenModal(false);
      setSelectStatus(status);
    }
  };
  return (
    <ModalContainer>
      <ModalContent>
        <Options>
          <DivOption onClick={() => handleSelectStatus('All')}>All</DivOption>
          <DivOption onClick={() => handleSelectStatus('Invoiced')}>
            Invoiced
          </DivOption>
          <DivOption onClick={() => handleSelectStatus('Non Invoiced')}>
            Non Invoiced
          </DivOption>
          <DivOption onClick={() => handleSelectStatus('Deleted')}>
            Deleted
          </DivOption>
        </Options>
      </ModalContent>
    </ModalContainer>
  );
};
