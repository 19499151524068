import { useState } from 'react';

import { SelectContainer, SelectButton, OptionsMenu, Option } from './styles';

import sortIcon from '../../assets/sortDown.svg';

type SelectProps = {
  options: string[];
  onChange: (value: string) => void;
  value: string;
};

export const Select = ({ options, onChange, value }: SelectProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  return (
    <SelectContainer>
      <SelectButton onClick={() => setIsOptionsOpen(!isOptionsOpen)}>
        <span>{value}</span>
        <img src={sortIcon} alt="Select down" />
      </SelectButton>
      {!!isOptionsOpen && (
        <OptionsMenu>
          {options.map((option) => (
            <Option
              key={option}
              onClick={() => {
                onChange(option);
                setIsOptionsOpen(false);
              }}>
              {option}
            </Option>
          ))}
        </OptionsMenu>
      )}
    </SelectContainer>
  );
};
