import styled from 'styled-components';

type ButtonProps = {
  color?: 'green' | 'white';
};

export const ModalContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 40%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: none;
`;

export const ModalContent = styled.div`
  height: auto;
  width: 420px;
  padding: 2.5rem 3.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 8;
  position: absolute;
  text-align: center;
  font-weight: 500;
  line-height: 20px;

  background-color: var(--white);
  color: var(--black);
  font-size: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0 10px 11px 0 var(--shadow-20);
  border: 1px solid var(--gray-20);

  i {
    font-size: 10px;
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 1.25rem;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  padding: 10px 15px;
  border-radius: 5px;

  border: 2px solid var(--green-200);
  background-color: ${({ color }) =>
    color === 'green' ? 'var(--green-200)' : 'var(--white)'};
  color: ${({ color }) =>
    color === 'green' ? 'var(--white)' : 'var(--green-200)'};
`;
