import styled from 'styled-components';

type ContainerProps = {
  active?: boolean;
};

export const Container = styled.div<ContainerProps>`
  border: 1px solid var(--gray-650);
  border-radius: 20px;
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2rem 1rem 1rem;

  i {
    color: var(--gray-650);
    cursor: pointer;
    margin-left: auto;
  }

  input[type='radio'] {
    accent-color: var(--green);
    border: 0px;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }

  input[type='text']::placeholder {
    color: var(--gray-650);
  }

  @media (max-width: 1200px) {
    margin-top: 1rem;
  }

  ${({ active }) =>
    active &&
    `
    border: 1px solid var(--green);

    label {
      color: var(--black);
    }
    `}
`;

export const Label = styled.label`
  color: var(--gray-650);
`;

export const Input = styled.input`
  border: none;
`;
