import React, { useEffect, useState } from 'react';
import { history } from '../../services/history';
import { useInvoiceSelected } from '../../contexts';
import { getInvoiceById } from '../../services/invoices';

import {
  TableInvoiceDetails,
  Spinner,
  ValidationMessage
} from '../../components';

import {
  Container,
  DivInvoices,
  Title,
  TableContainer,
  DivError
} from './styles';

type InvoiceData = {
  id: string;
  description: string;
  quantity: string;
  unitPrice: number;
  tax: number;
  amountEur: number;
};

export const InvoiceDetails: React.FC = () => {
  const { selectedInvoice } = useInvoiceSelected();

  const [invoiceData, setInvoiceData] = useState<InvoiceData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleOrderById = async () => {
    setLoading(true);

    try {
      const invoice = await getInvoiceById(selectedInvoice?.id);
      setInvoiceData(invoice.lineItems);
      setLoading(false);
      if (invoice.lineItems.length === 0) {
        setError(true);
      }
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    handleOrderById();
  }, []);

  return (
    <Container>
      <Title>
        <h1>{selectedInvoice.company}</h1>
        <div role="presentation" onClick={() => history.goBack()}>
          <p>Invoices</p>
        </div>
      </Title>
      <DivInvoices>
        <TableContainer>
          {invoiceData && <TableInvoiceDetails contentPage={invoiceData} />}
          {loading && <Spinner />}
          {error && (
            <DivError>
              <ValidationMessage error>
                Invoices Details Not Found
              </ValidationMessage>
            </DivError>
          )}
        </TableContainer>
      </DivInvoices>
    </Container>
  );
};
