import styled from 'styled-components';

type MenuProps = {
  active?: boolean;
};

export const Text = styled.p`
  color: var(--gray-700);
  font-size: 1rem;
  font-weight: 700;
  line-height: 3.18rem;
  margin-left: 0.8rem;
`;

export const Menu = styled.div<MenuProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;

  i {
    margin-left: 3rem;
    color: var(--gray-700);
  }

  :hover {
    background-color: var(--gray-100);
  }

  ${({ active }) =>
    active &&
    `
      background-color: var(--gray-100);
      border-right: 4px solid var(--green);

      p, i {
        color:var(--black);
      }
    `}
`;
