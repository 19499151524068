import styled from 'styled-components';

export const Container = styled.small`
  color: var(--green);
  font-size: 0.85rem;
  margin-top: 1rem;
  width: 70%;

  i {
    background-color: var(--background-green);
    border-radius: 50%;
    margin-right: 5px;
  }
`;
