import React, { useState } from 'react';
import { sendFiles } from '../../services/upload';

import {
  FileInput,
  Button,
  ValidationMessage,
  Spinner
} from '../../components';

import { Container, DivUpload, DivButton, DivMessage, Title } from './styles';

export const UploadFile: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({
    error: '',
    success: ''
  });
  const [successUpload, setSuccessUpload] = useState<boolean>(false);
  const [errorUpload, setErrorUpload] = useState<boolean>(false);

  const uploadFile = async () => {
    setIsLoading(true);

    try {
      await sendFiles({ file });
      setIsLoading(false);
      setFileName('');
      setFile(undefined);
      setUploadMessage({
        ...uploadMessage,
        success: 'File successfully uploaded.'
      });
      setSuccessUpload(true);
    } catch (error: any) {
      setIsLoading(false);
      setFileName('');
      setFile(undefined);
      setUploadMessage({ ...uploadMessage, error: error.response.data.error });
      setErrorUpload(true);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent) => {
    setFileName('');
    setUploadMessage({ ...uploadMessage, error: '', success: '' });
    setSuccessUpload(false);
    setErrorUpload(false);
    setFile(undefined);

    const fileRead: any = event.target;
    const fileUploaded = fileRead?.files[0];
    setFile(fileUploaded);

    if (fileUploaded) {
      setFileName(fileUploaded.name);
    }
  };

  return (
    <Container>
      <Title>
        <h1>Upload CSV</h1>
      </Title>
      <DivUpload>
        <FileInput
          fileName={fileName}
          onChange={handleFileSelect}
          active={file !== undefined}
          setFile={setFile}
          setFileName={setFileName}
        />

        <DivButton>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Button active={!!file} disabled={!file} onClick={uploadFile}>
                Send file
              </Button>

              {errorUpload && (
                <DivMessage>
                  <ValidationMessage error>
                    {uploadMessage.error}
                  </ValidationMessage>
                </DivMessage>
              )}

              {successUpload && (
                <DivMessage>
                  <ValidationMessage success>
                    {uploadMessage.success}
                  </ValidationMessage>
                </DivMessage>
              )}
            </>
          )}
        </DivButton>
      </DivUpload>
    </Container>
  );
};
