import { apiFile } from './api';

export async function sendFiles(props: { file: File | any }) {
  const token = sessionStorage.getItem('@fruit_people-token');
  apiFile.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const fileUpload = props.file;
  const dataFile = new FormData();
  dataFile.append('file', fileUpload);

  const response = await apiFile.post('/v1/order/file/upload', dataFile);
  return response.data;
}
