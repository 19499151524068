/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { history } from '../../../services/history';
import {
  saveCompanyConfig,
  getConfiguration
} from '../../../services/settings';
import { useSettingsSelected } from '../../../contexts';
import { verifyDate } from '../../../utils/verifyDate';

import {
  Button,
  InputSettings,
  PickDateModal,
  AutomaticallyModal,
  Spinner,
  ValidationMessage
} from '../../../components';

import {
  Container,
  DivSettings,
  Title,
  DivInvoice,
  InputInvoice,
  PickDate,
  DivTemplates,
  DivButton,
  InputTemplates,
  Templates,
  Purchase,
  DivMessage
} from './styles';

export const SettingsConfiguration: React.FC = () => {
  const { selectedSettings } = useSettingsSelected();

  const [openPickDate, setOpenPickDate] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const [openAutomatically, setOpenAutomatically] = useState<
    boolean | undefined
  >(false);
  const [automatic, setAutomatically] = useState<boolean | undefined>(false);
  const [template, setTemplate] = useState('');
  const [templateFull, setTemplateFull] = useState<boolean | undefined>(false);
  const [templatePartial, setTemplatePartial] = useState<boolean | undefined>(
    false
  );
  const [purchaseOrder, setPurchase] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState({
    date: '',
    period: '',
    frequency: '',
    initialDate: '',
    finalDate: '',
    repeat: false
  });

  const handleSaveConfiguration = async () => {
    let configuration: any;
    setLoading(true);

    if (dateTime.frequency.toUpperCase() !== 'DOES_NOT_REPEAT') {
      configuration = {
        automatic,
        date: dateTime.date,
        frequency: dateTime.frequency.toUpperCase(),
        period: dateTime.period.toUpperCase(),
        purchaseOrder,
        repeat: dateTime.repeat,
        template
      };
    } else {
      configuration = {
        automatic,
        beginPeriodDate: dateTime.initialDate,
        date: dateTime.date,
        endPeriodDate: dateTime.finalDate,
        frequency: dateTime.frequency.toUpperCase(),
        period: dateTime.period.toUpperCase(),
        purchaseOrder,
        repeat: dateTime.repeat,
        template
      };
    }

    try {
      await saveCompanyConfig(configuration, selectedSettings?.id);
      history.push(`/settings`);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const handleChangeInputPO = (_event: any) => {
    const { value, maxLength } = _event.target;
    const message = value.slice(0, maxLength);
    setPurchase(message);
  };

  const handleGetConfiguration = async () => {
    setLoading(true);
    try {
      const config = await getConfiguration(selectedSettings?.id);
      setLoading(false);
      setAutomatically(config.automatic);
      setPurchase(config.purchaseOrder);
      setTemplate(config.template);

      if (config.template === 'SUMMARIZED') {
        setTemplatePartial(true);
      } else if (config.template === 'FULL_ITEMISED') {
        setTemplateFull(true);
      };

      if (!config.beginPeriodDate) {
        const date = new Date();
        const formatDateInitial = `${verifyDate(date.getDate())}/${(verifyDate(date.getMonth() + 1).toString())
          }/${date.getFullYear()}`;
        const formatDateFinal = `${verifyDate(date.getDate() + 1).toString()}/${(verifyDate(date.getMonth() + 1).toString())
          }/${date.getFullYear()}`;

        setDateTime({
          ...dateTime,
          initialDate: formatDateInitial,
          date: config.date,
          finalDate: formatDateFinal,
          frequency: config.frequency,
          period: config.period,
          repeat: config.repeat
        });
      } else {
        setDateTime({
          ...dateTime,
          initialDate: config.beginPeriodDate,
          date: config.date,
          finalDate: config.endPeriodDate,
          frequency: config.frequency,
          period: config.period,
          repeat: config.repeat
        });
      }
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const handleChangeButtonActiveState = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <Button
            type="button"
            onClick={handleSaveConfiguration}
            active={isActive}>
            Save
          </Button>
        )}
      </>
    )
  };

  useEffect(() => {
    const activeButton =
      (templateFull || templatePartial) &&
      dateTime.date.length > 0 &&
      dateTime.frequency.length > 0 &&
      dateTime.period.length > 0 &&
      !invalidDate;

    if (activeButton) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [templateFull, templatePartial, dateTime, invalidDate]);

  useEffect(() => {
    handleChangeButtonActiveState();
  }, [isActive]);

  useEffect(() => {
    handleGetConfiguration();
  }, []);

  return (
    <Container>
      <Title>
        <h1>{selectedSettings?.company}</h1>
        <div role="presentation" onClick={() => history.goBack()}>
          <p>Companies</p>
        </div>
      </Title>
      <DivSettings>
        <DivInvoice>
          <InputInvoice>
            <PickDate>
              <p>Invoice</p>
              <InputSettings
                label={dateTime.date || 'Pick date & time'}
                active={!!dateTime.date}
                hasIcon
                onClick={() => setOpenPickDate(!openPickDate)}
              />
              {openPickDate && (
                <PickDateModal
                  setOpenPickDate={setOpenPickDate}
                  setDateTime={setDateTime}
                  dateTime={dateTime}
                  invalidDate={invalidDate}
                  setInvalidDate={setInvalidDate}
                />
              )}
            </PickDate>

            <PickDate>
              <p>Send automatically</p>
              <InputSettings
                label="Automatically"
                input
                active={automatic}
                checked={automatic}
                type="radio"
                onClick={() => {
                  if (automatic) {
                    setAutomatically(false);
                    setOpenAutomatically(false);
                  } else {
                    setAutomatically(true);
                    setOpenAutomatically(true);
                  }
                }}
              />
              {openAutomatically && (
                <AutomaticallyModal
                  setAutomatically={setAutomatically}
                  setOpenAutomatically={setOpenAutomatically}
                />
              )}
            </PickDate>
          </InputInvoice>
        </DivInvoice>

        <DivTemplates>
          <Templates>
            <p>Templates</p>
            <InputTemplates>
              <InputSettings
                label="Full itemised"
                input
                type="radio"
                name="template"
                active={templateFull}
                checked={templateFull}
                onClick={() => {
                  setTemplateFull(true);
                  setTemplatePartial(false);
                  setTemplate('FULL_ITEMISED');
                }}
              />
              <InputSettings
                label="Summarized"
                input
                type="radio"
                name="template"
                active={templatePartial}
                checked={templatePartial}
                onClick={() => {
                  setTemplatePartial(true);
                  setTemplateFull(false);
                  setTemplate('SUMMARIZED');
                }}
              />
            </InputTemplates>
          </Templates>

          <Purchase>
            <p>Purchase Order (PO)</p>
            <InputSettings
              input
              type="text"
              active={purchaseOrder?.length !== 0}
              value={purchaseOrder}
              onChange={handleChangeInputPO}
            />
          </Purchase>
        </DivTemplates>

        <DivButton>
          {handleChangeButtonActiveState()}
          {error && (
            <DivMessage>
              <ValidationMessage error>Process Failed</ValidationMessage>
            </DivMessage>
          )}
        </DivButton>
      </DivSettings>
    </Container>
  );
};
