import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 110px 40px 0 60px;

  h2 {
    font-size: 25px;
    margin-bottom: 47px;
    font-weight: 500;
  }
`;

export const PageHeader = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const UploadButton = styled.button`
  background-color: var(--green-200);
  border: none;
  border-radius: 10px;
  color: var(--white);
  padding: 10px 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 23px;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ProcessTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SearchContainer = styled.div`
  width: 280px;
`;

export const DateFilterContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const DateFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--gray-650);

  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;
