/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination
} from '@mui/material';

import { useSettingsSelected } from '../../../contexts';

import { getFilterCompany, getCompanyById } from '../../../services/settings';
import { history } from '../../../services/history';

import { Button, Divider, Spinner, TablePaginationActions, ValidationMessage } from '../..';

import sortIcon from '../../../assets/sortIcon.png';

import {
  SortIcon,
  DivPagination,
  Data,
  ContentRow,
  DivButton,
  DivMessage
} from './styles';

import { StyledTableCell, StyledTableRow } from '../styles';

interface TableLabelProps {
  from: any;
  to: any;
  count: any;
}

type Order = 'asc' | 'desc';

interface Data {
  company: string;
  id: number;
  lastInvoice: string;
  nextInvoice: string;
  purchaseOrder: string;
  frequency: string;
  automatic: boolean;
  login: number;
  idConfig: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface TableContentProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  contentPage: any;
  filtered: any;
  search: string;
  frequency: string;
  totalElements: number;
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
}

const Head = (props: EnhancedTableProps) => {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            className="table-cell-head head company"
            padding="checkbox"
            sortDirection={orderBy === 'company' && order}>
            <TableSortLabel
              active={orderBy === 'company'}
              direction={orderBy === 'company' ? order : 'asc'}
              onClick={createSortHandler('company')}>
              Companies
              {orderBy === 'company' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Customer number
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Invoice Frequency
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'customerConfig.lastInvoice' && order}>
            <TableSortLabel
              active={orderBy === 'customerConfig.lastInvoice'}
              direction={orderBy === 'customerConfig.lastInvoice' ? order : 'asc'}
              onClick={createSortHandler('customerConfig.lastInvoice')}>
              Last invoice
              {orderBy === 'customerConfig.lastInvoice' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'customerConfig.nextInvoice' && order}>
            <TableSortLabel
              active={orderBy === 'customerConfig.nextInvoice'}
              direction={orderBy === 'customerConfig.nextInvoice' ? order : 'asc'}
              onClick={createSortHandler('customerConfig.nextInvoice')}>
              Next invoice
              {orderBy === 'customerConfig.nextInvoice' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Automatically
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Purchase order
          </TableCell>

        </TableRow>
        <TableRow>
          <TableCell className="table-cell-divider" colSpan={7} size="small">
            <div>
              <Divider />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableSettings = (props: TableContentProps) => {
  const { setSettingsSelectedState } = useSettingsSelected();

  let getCompaniesData;
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    contentPage,
    filtered,
    search,
    frequency,
    totalElements,
    order,
    setOrder,
    orderBy,
    setOrderBy
  } = props;

  const [rows, setRows] = useState<Data[]>([]);
  const [selected] = useState<readonly string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleGetCompaniesPerPage = async () => {
    getCompaniesData = await getFilterCompany(
      frequency,
      search,
      rowsPerPage,
      page,
      order,
      orderBy
    );

    if (getCompaniesData) {
      setRows(getCompaniesData.content);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function defaultLabelDisplayedRows(label: TableLabelProps) {
    const { from, to, count } = label;
    return `Show ${from}-${to} of ${count !== -1 ? count : `more than ${to}`
      } results`;
  };

  const handleConfigCompany = async (id: number, company: string, idConfig: number) => {
    setLoading(true);
    setSettingsSelectedState(company, idConfig);

    try {
      await getCompanyById(id);
      history.push(`/settings/${id}`);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const handleChangeContent = () => {
    return (
      <>
        {rows ? (
          <TableBody>
            <>
              {rows?.map((row) => {

                let automatic;

                if (row.automatic) {
                  automatic = 'YES'
                } else {
                  automatic = 'NO'
                };

                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => {
                      handleConfigCompany(row.id, row.company, row.idConfig);
                    }}>
                    <StyledTableCell scope="row">
                      <Data>
                        <p>{row.company}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <ContentRow>
                        <p>{row.login}</p>
                      </ContentRow>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <ContentRow>
                        <p>{row.frequency}</p>
                      </ContentRow>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <ContentRow>
                        <p>{row.lastInvoice}</p>
                      </ContentRow>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <ContentRow>
                        <p>{row.nextInvoice}</p>
                      </ContentRow>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <ContentRow>
                        <p>{automatic}</p>
                      </ContentRow>
                    </StyledTableCell>
                    <StyledTableCell scope="row" padding="none" align="center">
                      <ContentRow>
                        <p>{row.purchaseOrder}</p>
                      </ContentRow>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </>
          </TableBody>
        ) : (
          <Spinner />
        )}
      </>
    );
  };

  useEffect(() => {
    if (filtered?.content.length > 0) {
      setRows(filtered?.content);
    }
  }, [filtered]);

  useEffect(() => {
    setRows(contentPage);
  }, [contentPage]);

  useEffect(() => {
    handleGetCompaniesPerPage();
  }, [page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    handleChangeContent();
  }, [rows]);


  return (
    <>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <Head
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={contentPage.length}
          />

          {handleChangeContent()}
        </Table>
      </TableContainer>

      {!!rows?.length && (
        <DivPagination>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 20]}
            colSpan={3}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={defaultLabelDisplayedRows}
            ActionsComponent={TablePaginationActions}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </DivPagination>
      )}

      <DivButton>
        {loading && <Spinner />}
        {error && (
          <DivMessage>
            <ValidationMessage error>
              Company not found
            </ValidationMessage>
          </DivMessage>
        )}
      </DivButton>
    </>
  );
};
