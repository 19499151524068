import React, { useEffect, useState } from 'react';
import { history } from '../../services/history';
import { useOrderSelected } from '../../contexts';
import { getOrderById } from '../../services/orders';

import {
  TableOrderDetails,
  Spinner,
  ValidationMessage
} from '../../components';

import {
  Container,
  DivInvoices,
  Title,
  TableContainer,
  DivLeft,
  DivRight,
  DivError
} from './styles';

export const OrdersDetails: React.FC = () => {
  const { selectedOrder } = useOrderSelected();

  const [page, setPage] = useState(0);
  const [orderData, setOrderData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [countElements, setCountElements] = useState(0);

  const handleOrderById = async () => {
    setLoading(true);

    try {
      const order = await getOrderById(selectedOrder?.id);
      setOrderData(order.orderItemList);
      setCountElements(order.orderItemList.length);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    handleOrderById();
  }, []);

  return (
    <Container>
      <Title>
        <DivLeft>
          <h1>Order ID: {selectedOrder?.orderId}</h1>
          <span>{selectedOrder?.company}</span>
        </DivLeft>
        <DivRight>
          <div role="presentation" onClick={() => history.goBack()}>
            <p>Orders</p>
          </div>
          <span>{selectedOrder?.total}</span>
        </DivRight>
      </Title>
      <DivInvoices>
        <TableContainer>
          {orderData && (
            <TableOrderDetails
              contentPage={orderData}
              page={page}
              setPage={setPage}
              totalElements={countElements}
            />
          )}
          {loading && <Spinner />}
          {error && (
            <DivError>
              <ValidationMessage error>Order Not Found</ValidationMessage>
            </DivError>
          )}
        </TableContainer>
      </DivInvoices>
    </Container>
  );
};
