import styled from 'styled-components';

type ButtonProps = {
  active?: boolean;
};

export const Container = styled.button<ButtonProps>`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 1rem 0.5rem;

  background-color: var(--gray-600);
  color: var(--white);
  border: none;
  border-radius: 0.4rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  i {
    margin-right: 1rem;
  }

  ${({ active }) =>
    active &&
    `
      background-color: var(--green);
    `}
`;
