import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const DivLogin = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1rem 5rem 1rem;

  img {
    width: 35%;
  }

  @media (max-width: 988px) {
    width: 100%;

    img {
      width: 30%;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 20%;

  @media (max-width: 988px) {
    width: 80%;
  }
`;

export const DivButton = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const DivResetPasswordMessage = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Artboard = styled.aside`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 988px) {
    display: none;
  }
`;
