import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
`;

export const SelectButton = styled.div`
  cursor: pointer;
  padding: 10px 11px;
  border: 1px solid var(--gray-650);
  border-radius: 10px;
  color: var(--gray-650);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;

  span {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const OptionsMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  z-index: 10;
  background: var(--white);
  border-radius: 20px;
  box-shadow: 10px 20px 60px var(--shadow-50);
`;

export const Option = styled.div`
  cursor: pointer;
  color: var(--gray-650);
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 20px;

  &:hover {
    background: var(--gray-50);
  }
`;
