import styled from 'styled-components';

type inputProps = {
  hasIcon?: boolean;
};

export const Container = styled.div<inputProps>`
  width: 90%;
  margin-top: 1.5rem;
  position: relative;

  label {
    font-size: 1rem;
    font-weight: bold;
    color: var(--gray-750);
  }

  > input {
    height: 3rem;
    padding: ${(props) => (props.hasIcon ? '1rem 3rem' : '1rem')};
    width: 100%;
    margin: 6px 0;

    font-size: 1rem;
    color: var(--gray-750);

    display: flex;
    align-items: center;

    &[type='password'] {
      font-size: 1.3rem;
    }

    border-radius: 0.4rem;
    border: 1px solid var(--gray-750);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2.4px var(--green);
    }

    @media only screen and (max-width: 988px) {
      padding: ${(props) => (props.hasIcon ? '1rem 2.5rem' : '1rem')};
    }
  }

  > i {
    color: var(--icons);
    margin: 2.4rem 14px;
    position: absolute;
    left: 0.5rem;

    @media (max-width: 988px) {
      margin: 2.5rem 0;
      left: 0.75rem;
    }
  }
`;
