import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import {
  ValidationMessage,
  EmailInput,
  SuccessMessage,
  Button
} from '../../components';

import {
  Container,
  Artboard,
  Form,
  DivLogin,
  DivButton,
  DivResetPasswordMessage
} from './styles';

import logo from '../../assets/logo.png';
import background from '../../assets/background.jpg';

type LoginValues = {
  email: string;
};

export const ResetPassword: React.FC = () => {
  const formMethods = useForm<LoginValues>();
  const [loginError, setLoginError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  async function handleSubmitResetPassword({ email }: LoginValues) {
    if (email === 'fruit_people@test.com') {
      setSuccessMessage('TESTE');
      setLoginError(false);
    } else {
      setLoginError(true);
      setErrorMessage('Invalid E-mail');
    }
  }

  return (
    <Container>
      <DivLogin>
        <img src={logo} alt="Fruit People" />

        <FormProvider {...formMethods}>
          <Form
            onSubmit={formMethods.handleSubmit((data) =>
              handleSubmitResetPassword(data)
            )}
            noValidate>
            <EmailInput label="E-mail" hasIcon autoFocus />

            <DivButton>
              <Button type="submit" active>
                Send
              </Button>
            </DivButton>

            {loginError && (
              <DivResetPasswordMessage>
                <ValidationMessage>{errorMessage}</ValidationMessage>
              </DivResetPasswordMessage>
            )}

            {successMessage && (
              <DivResetPasswordMessage>
                <SuccessMessage>{successMessage}</SuccessMessage>
              </DivResetPasswordMessage>
            )}
          </Form>
        </FormProvider>
      </DivLogin>
      <Artboard>
        <img src={background} alt="Fruit People" />
      </Artboard>
    </Container>
  );
};
