import styled from 'styled-components';

export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ContentRow = styled.div`
  p {
    color: var(--gray-700) !important;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
  }
`;
