import { InputHTMLAttributes } from 'react';

import { Container, Label, Input } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasIcon?: boolean;
  label?: string;
  input?: boolean;
  type?: string;
  name?: string;
  checked?: boolean;
  active?: boolean;
  value?: string;
}

export const InputSettings: React.FC<InputProps> = ({
  label,
  hasIcon,
  input,
  type,
  name,
  checked,
  active,
  value,
  ...rest
}) => {
  return (
    <Container {...rest} active={active}>
      {input && (
        <Input
          {...rest}
          type={type}
          placeholder="000000"
          name={name}
          checked={checked}
          value={value}
          maxLength={40}
        />
      )}
      <Label>{label}</Label>
      {hasIcon && <i className="fas fa-chevron-down" />}
    </Container>
  );
};
