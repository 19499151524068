import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card,
  SearchInput,
  Select,
  TableProcessesGo2Stream
} from '../../../components';

import uploadIcon from '../../../assets/upload.svg';

import {
  Container,
  PageHeader,
  UploadButton,
  DateFilterContainer,
  DateFilters,
  ProcessTableHeader,
  SearchContainer,
  TableContainer
} from './styles';

export const ProcessList = () => {
  const [firstDateFilter, setFirstDateFilter] = useState('2021/11');
  const [secondDateFilter, setSecondDateFilter] = useState('2021/12');

  const dateOptions = ['2021/09', '2021/10', '2021/11', '2021/12', '2022/01'];

  const history = useHistory();

  return (
    <Container>
      <PageHeader>
        <h2>Process list</h2>
        <UploadButton onClick={() => history.push('/go2stream/upload')}>
          <img src={uploadIcon} alt="upload" />
          Upload files
        </UploadButton>
      </PageHeader>
      <Card>
        <ProcessTableHeader>
          <SearchContainer>
            <SearchInput placeholder="Search" icon="fa-search" />
          </SearchContainer>
          <DateFilters>
            <span>Select date</span>
            <DateFilterContainer>
              <Select
                options={dateOptions}
                value={firstDateFilter}
                onChange={setFirstDateFilter}
              />
              <Select
                options={dateOptions}
                value={secondDateFilter}
                onChange={setSecondDateFilter}
              />
            </DateFilterContainer>
          </DateFilters>
        </ProcessTableHeader>
        <TableContainer>
          <TableProcessesGo2Stream />
        </TableContainer>
      </Card>
    </Container>
  );
};
