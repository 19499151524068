import styled from 'styled-components';

export const Overlay = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 11;
`;

export const Loading = styled.div`
  width: 50px;
  height: 50px;
  border: 8px solid var(--green);
  border-top-color: var(--background-grey);
  border-radius: 50%;

  z-index: 12;

  animation: spin 1.5s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
