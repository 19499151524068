import styled from 'styled-components';

type LayoutProps = {
  isSidebarOpen?: boolean;
};

export const Container = styled.div<LayoutProps>`
  height: 100vh;
  width: 100vw;
`;

export const Main = styled.div`
  margin-left: 15rem;
  max-height: 100%;
`;
