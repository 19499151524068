/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  active?: boolean;
}

export const ButtonSmall: React.FC<ButtonProps> = ({
  icon,
  active,
  children,
  ...rest
}) => {
  return (
    <Container active={active} disabled={!active} {...rest}>
      {!!icon && <i className={`${icon}`} />}
      {children}
    </Container>
  );
};
