import backIcon from '../../../assets/back.svg';

import { Container } from './styles';

type BackButtonProps = {
  onClick: () => void;
};

export const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <Container onClick={onClick}>
      <img src={backIcon} alt="Back" />
    </Container>
  );
};
