import styled from 'styled-components';

export const SideBarContainer = styled.div`
  background-color: var(--gray-20);
  max-width: 16rem;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  border-radius: 0 0.75rem 0.75rem 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 988px) {
    max-width: 14rem;
  }
`;

export const Logo = styled.img`
  width: 60%;
  margin: 20% 0 10% 20%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  color: var(--gray-700);
  font-size: 1rem;
  font-weight: 700;
  line-height: 3.18rem;
  margin-left: 0.8rem;
`;

export const DivLogout = styled.div`
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  hr {
    height: 0.2rem;
    width: 100%;
    margin: 0 auto;
    background-color: var(--gray-100);
    border: 0;
    margin: 0.8rem auto;
  }
`;

export const Logout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--gray-700);

  :hover {
    p,
    i {
      color: var(--black);
    }
  }
`;
