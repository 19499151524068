import React, { createContext, useContext, useState } from 'react';

type InvoiceSelectedContext = {
  selectedInvoice: { company: string; id: string };
  setInvoiceSelectedState(company: string, id: string): void;
};

export const InvoiceSelectedContext = createContext<InvoiceSelectedContext>(
  {} as InvoiceSelectedContext
);

export const InvoiceSelectedContextProvider: React.FC = ({ children }) => {
  const [selectedInvoice, setSelectedInvoice] = useState({
    company: '',
    id: ''
  });

  const setInvoiceSelectedState = (company: string, id: string) => {
    setSelectedInvoice({
      ...selectedInvoice,
      company,
      id
    });
  };

  return (
    <InvoiceSelectedContext.Provider
      value={{
        selectedInvoice,
        setInvoiceSelectedState
      }}>
      {children}
    </InvoiceSelectedContext.Provider>
  );
};

export function useInvoiceSelected(): InvoiceSelectedContext {
  const context = useContext(InvoiceSelectedContext);
  return context;
}
