import React, { useState, useEffect } from 'react';

import {
  SearchInput,
  Spinner,
  TableSettings,
  FrequencyModal,
  ButtonClear
} from '../../components';
import { getCompanies, getFilterCompany } from '../../services/settings';

import {
  Container,
  DivSettings,
  Title,
  DivFilters,
  TableContainer,
  NotFound,
  SelectFrequency,
  DivFrequency,
  Label,
  DivSearch
} from './styles';

type CompanyData = {
  company: string;
  id: number;
};

type Order = 'asc' | 'desc';

export const Settings: React.FC = () => {
  let filteredOrders: any;

  const [companiesData, setCompaniesData] = useState<CompanyData[]>();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('company');
  const [noCompanyFound, setNoCompanyFound] = useState<boolean>(false);
  const [companiesConfig, setCompaniesConfig] = useState<any>({
    totalPages: 0,
    totalElements: 0
  });
  const [filteredData, setFilteredData] = useState<CompanyData[]>();
  const [filter, setFilter] = useState('');
  const [selectFrequency, setSelectFrequency] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState<string>('');

  const getCompaniesData = async () => {
    const companies = await getCompanies();
    if (companies) {
      setCompaniesData(companies.content);
      setCompaniesConfig({
        ...companiesConfig,
        totalPages: companies.totalPages,
        totalElements: companies.totalElements
      });
    }
  };

  const handleFilter = async (
    frequencySelected: string,
    searchQuery: string,
    rowsPerPageSelected: number,
    currentPage: number,
    typeOrder: Order,
    orderedBy: string
  ) => {
    setSearch(searchQuery);

    filteredOrders = await getFilterCompany(
      frequencySelected,
      searchQuery,
      rowsPerPageSelected,
      currentPage,
      typeOrder,
      orderedBy,
      setNoCompanyFound
    );

    if (filteredOrders) {
      setFilteredData(filteredOrders);
      setRowsPerPage(5);
      setCompaniesConfig({
        ...companiesConfig,
        totalPages: filteredOrders.totalPages,
        totalElements: filteredOrders.totalElements
      });
    }
  };

  const handleFindFilter = () => {
    return (
      <>
        {companiesConfig?.totalPages === 0 && !!filteredData ? (
          <NotFound>
            <i className="fas fa-exclamation-triangle" />
            No company found
          </NotFound>
        ) : (
          <TableContainer>
            {companiesData ? (
              <TableSettings
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                contentPage={companiesData}
                filtered={filteredData}
                search={search}
                frequency={selectFrequency.toLocaleUpperCase()}
                totalElements={companiesConfig.totalElements}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            ) : (
              <Spinner />
            )}
          </TableContainer>
        )}
      </>
    );
  };

  useEffect(() => {
    handleFindFilter();
  }, [filteredData]);

  useEffect(() => {
    setNoCompanyFound(false);

    handleFilter(
      selectFrequency.toLocaleUpperCase(),
      filter,
      rowsPerPage,
      page,
      order,
      orderBy
    );
  }, [filter]);

  useEffect(() => {
    setNoCompanyFound(false);
    setPage(0);
    handleFilter(
      selectFrequency.toLocaleUpperCase(),
      filter,
      rowsPerPage,
      page,
      order,
      orderBy
    );
  }, [selectFrequency]);

  useEffect(() => {
    if (noCompanyFound) {
      setTimeout(() => {
        setFilter('');
        setSelectFrequency('');
      }, 5000);
    }
  }, [noCompanyFound]);

  useEffect(() => {
    getCompaniesData();
    return setFilteredData(undefined);
  }, []);

  return (
    <Container>
      <Title>
        <h1>Companies</h1>
      </Title>
      <DivSettings>
        <DivFilters>
          <DivSearch>
            <SearchInput
              placeholder="Search"
              icon="fa-search"
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value);
                setPage(0);
              }}
            />
            <ButtonClear
              onClick={() => {
                setFilter('');
              }}>
              Clear
            </ButtonClear>
          </DivSearch>
          <DivFrequency>
            <p>Filter Frequency</p>
            <SelectFrequency onClick={() => setOpenModal(!openModal)}>
              <Label active={!!selectFrequency}>
                {selectFrequency || 'Select'}
              </Label>
              <i className="fas fa-chevron-down" />
            </SelectFrequency>
            {openModal && (
              <FrequencyModal
                setOpenModal={setOpenModal}
                setSelectFrequency={setSelectFrequency}
              />
            )}
          </DivFrequency>
        </DivFilters>

        {handleFindFilter()}
      </DivSettings>
    </Container>
  );
};
