import styled from 'styled-components';

export const Container = styled.button`
  border-radius: 20px;
  width: 3rem;
  height: 30px;
  border: 1px solid var(--green);
  color: var(--green);
  background-color: var(--white);
  margin-top: 1.65rem;

  &:hover {
    color: var(--white);
    background-color: var(--green);
  }
`;
