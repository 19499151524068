import React, { Dispatch, SetStateAction } from 'react';
import { ButtonSmall } from '../..';

import { ModalContainer, ModalContent, Text, DivButtons } from './styles';

interface ModalProps {
  setAutomatically: Dispatch<SetStateAction<boolean | undefined>>;
  setOpenAutomatically: Dispatch<SetStateAction<boolean | undefined>>;
}

export const AutomaticallyModal: React.FC<ModalProps> = ({
  setAutomatically,
  setOpenAutomatically
}) => {
  return (
    <ModalContainer>
      <ModalContent>
        <Text>
          Switching this On will send invoices automatically at the date and
          time configured. Do you want to switch it on?
        </Text>
        <DivButtons>
          <ButtonSmall
            active
            onClick={() => {
              if (setOpenAutomatically) {
                setOpenAutomatically(false);
              }
            }}>
            Yes
          </ButtonSmall>
          <ButtonSmall
            active
            onClick={() => {
              if (setAutomatically) {
                setAutomatically(false);
                setOpenAutomatically(false);
              }
            }}>
            No
          </ButtonSmall>
        </DivButtons>
      </ModalContent>
    </ModalContainer>
  );
};
