import React from 'react';

import { SideBar } from '../SideBar';

import { Container, Main } from './styles';

export const AppLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <Main>{children}</Main>
    </Container>
  );
};
