/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { green } from '@material-ui/core/colors';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { verifyDate } from '../../../utils/verifyDate';
import { Icon } from './styles';

interface DateProps {
  setSelectDate: Dispatch<SetStateAction<string | null>>;
  selectDate: any;
}

const defaultMaterialTheme = createTheme({
  palette: {
    primary: green
  },
  typography: {
    fontSize: 10
  },
  shape: {
    borderRadius: 12
  }
});

export const DateInputSettingsSmall: React.FC<DateProps> = ({
  selectDate,
  setSelectDate
}) => {
  let formatDate = selectDate.toString();
  const day = formatDate?.substring(0, 2);
  const month = formatDate?.substring(3, 5);
  const year = formatDate?.substring(6, 10);
  formatDate = `${month}/${day}/${year}`;

  const [formatedDate, setFormatedDate] = useState(formatDate);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formatDateInput = `${verifyDate(date.getDate())}/${verifyDate(
        date.getMonth() + 1
      ).toString()}/${date.getFullYear()}`;

      setSelectDate(formatDateInput);
      setFormatedDate(
        `${verifyDate(date.getMonth() + 1).toString()}/${verifyDate(
          date.getDate()
        )}/${date.getFullYear()}`
      );
    }
  };

  return (
    <div style={{ marginRight: '10px', marginTop: '10px' }}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Icon className="far fa-calendar-alt" />
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            value={formatedDate}
            format="dd/MM/yyyy"
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};
