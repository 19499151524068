import styled from 'styled-components';

type LabelProps = {
  active?: boolean;
};

export const ModalContainer = styled.div`
  position: relative;
`;

export const ModalContent = styled.div`
  width: 229px;
  max-width: 229px;
  height: auto;
  padding: 0.5rem 0.75em;
  margin-top: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 8;
  position: absolute;

  background-color: var(--white);
  color: var(--gray-650);
  border-radius: 0.75rem;
  box-shadow: 0 10px 11px 0 var(--shadow-20);
  border: 1px solid var(--gray-20);

  i {
    font-size: 10px;
    cursor: pointer;
  }
`;

export const Label = styled.label<LabelProps>`
  margin-left: 0.5rem;
  font-size: 0.81rem;
  font-weight: 600;
  line-height: 2.4;
  letter-spacing: -0.29px;
  color: var(--gray-650);

  input {
    margin-right: 0.25rem;
  }

  ${({ active }) =>
    active &&
    `
    color: var(--black);
    `}
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--gray-100);
    height: 2.5rem;
  }
`;

export const DivButton = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 0.5rem;
  margin-left: auto;
`;

export const DivPeriod = styled.div`
  position: relative;
  margin-top: 2.5rem;

  div {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: var(--white);
    color: var(--gray-650);
    border-radius: 0.75rem;
    box-shadow: 0 10px 11px 0 var(--shadow-20);
    border: 1px solid var(--gray-20);
    height: auto;
  }

  p {
    color: var(--gray-600);
    font-size: 0.81rem;
    margin-right: auto;
    padding-left: 1rem;
    width: 90%;
    border-radius: 0.5rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    &:hover {
      background-color: var(--gray-20);
      color: var(--gray-650);
    }
  }
`;

export const DoesNotRepeat = styled.div`
  display: flex;
  flex-direction: column;
  border: none !important;
  height: 6.25rem;
  width: 210px;
  max-width: 210px;
`;

export const Title = styled.p`
  font-size: 13px;
  color: var(--gray-650) !important;
`;

export const DivDate = styled.div`
  border: none !important;
  display: flex;
  flex-direction: column;
  width: 210px;
  height: 4rem;
  margin-top: 0.5rem;

  div {
    height: 2rem !important;
    margin-top: -0.25rem;
  }

  @media (max-width: 920px) {
    margin-top: 1.5rem;
  }
`;

export const DateInputContainer = styled.div`
  border: none !important;
  display: flex;

  p {
    font-weight: bold;
    margin-left: 0.5rem;
    padding-bottom: 0.75rem;
  }
`;

export const DateContent = styled.div`
  border: none !important;
  width: 6.8rem;

  span {
    color: var(--green);
  }

  p {
    height: 1rem;
    padding-bottom: 0.5rem;
    margin-top: -1.5rem;
    color: var(--danger) !important;
    font-size: 0.8rem;
  }
`;

export const DivMonth = styled.div`
  border: none !important;
  display: flex;
  align-items: center !important;
  width: 210px;
  height: 2rem;

  input[type='radio'] {
    accent-color: var(--green);
    margin-right: 1rem;
    border: 0px;
  }

  p {
    color: var(--black) !important;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
    padding-top: 0.75rem;
  }
`;
