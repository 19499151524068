/* eslint-disable react/require-default-props */
import React, { Dispatch, InputHTMLAttributes, SetStateAction } from 'react';

import { Container, LabelFile, Input, FileName } from './styles';

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  fileName: SetStateAction<string>;
  active: boolean;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  setFileName: Dispatch<SetStateAction<string>>;
}

export const FileInput: React.FC<FileInputProps> = ({
  fileName,
  active,
  setFile,
  setFileName,
  ...rest
}) => {
  const handleChangeValue = (event: any) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  return (
    <>
      <Container active={active}>
        <LabelFile htmlFor="uploadFile">
          {' '}
          <i className="far fa-paperclip" />
          CSV
        </LabelFile>
        <Input
          {...rest}
          id="uploadFile"
          type="file"
          accept=".csv,.xls,.xlsx"
          onClick={handleChangeValue}
        />
      </Container>
      <FileName>
        {fileName}
        {fileName && (
          <div
            role="presentation"
            onClick={() => {
              setFile(undefined);
              setFileName('');
            }}>
            <i className="far fa-times-circle" />
          </div>
        )}
      </FileName>
    </>
  );
};
