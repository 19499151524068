import React, { useState } from 'react';
import { history } from '../../../services/history';

import { Text, Menu } from './styles';

interface SideBarItemProps {
  text: string;
  icon: string;
  active: boolean;
  onClick: () => void;
}

export const SideBarItem: React.FC<SideBarItemProps> = ({
  text,
  icon,
  active,
  onClick,
  ...rest
}) => {
  return (
    <>
      <Menu active={active} {...rest} onClick={onClick}>
        <i className={`${icon}`} />
        <Text>{text}</Text>
      </Menu>
    </>
  );
};
