import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import dowloadIcon from '../../../assets/download.svg';

import { StyledTableRow, StyledTableCell } from '../styles';

const TableHeader = () => {
  const headers = ['ID', 'Date', 'Orders', 'Download'];

  return (
    <>
      <TableHead>
        <TableRow>
          {headers.map((header) => {
            return (
              <TableCell
                key={header}
                className="table-cell-head head"
                align="center"
                style={{ borderBottom: '2px solid var(--green)' }}
                padding="normal">
                {header}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableProcessesGo2Stream = () => {
  return (
    <>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHeader />
          <TableBody>
            <StyledTableRow hover tabIndex={-1}>
              <StyledTableCell scope="row" padding="none" align="center">
                Data
              </StyledTableCell>
              <StyledTableCell scope="row" padding="none" align="center">
                Data
              </StyledTableCell>
              <StyledTableCell scope="row" padding="none" align="center">
                Data
              </StyledTableCell>
              <StyledTableCell scope="row" padding="none" align="center">
                <img src={dowloadIcon} alt="download" />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
