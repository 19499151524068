import React from 'react';

import { Container } from './styles';

export const SuccessMessage: React.FC = ({ children }) => {
  return (
    <Container>
      <i className="far fa-check-circle" />
      {children}
    </Container>
  );
};
