import styled from 'styled-components';

type LabelProps = {
  active?: boolean;
};

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .MuiTableSortLabel-icon {
    display: none;
  }

  .head {
    border: 0;
    padding: 0;
    margin: 0;
  }

  .product {
    width: 12rem;
  }

  .table-pagination {
    width: 100%;
    height: 2.8rem;
    overflow: hidden;
    color: var(--gray-600) !important;
    font-weight: 600;
    border: none !important;
    font-size: 0.75rem;
  }

  .table-cell-head {
    color: var(--gray-650) !important;
    font-weight: 600;
  }

  .table-cell-divider {
    padding: 0 !important;
    margin: 0 !important;
    border: 0;

    div {
      height: 3rem;
      display: flex;
      justify-content: stretch;
      align-items: flex-start;
      margin: -1rem 0;
    }
  }

  @media (min-width: 1600px) {
    align-items: flex-start;
    margin-left: 5rem;
  }

  @media (max-width: 988px) {
    padding: 1.5rem 0.5rem 1rem 0.5rem;
    overflow-x: scroll;

    .table-cell-head {
      font-size: 11px !important;
    }
  }

  @media (max-width: 690px) {
    .MuiTablePagination-displayedRows {
      display: none !important;
    }

    .MuiTableCell-body {
      height: 75px !important;
    }
  }

  .table-pagination-content {
    border-radius: 1rem;
    background-color: var(--background-grey);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    height: 2.5rem;
  }
`;

export const Title = styled.div`
  width: 90%;
  max-width: 1300px;
  height: 5%;
  margin: 3rem 0 1rem 0;

  h1 {
    font-size: 1.5rem;
  }

  @media (min-width: 1600px) {
    margin-top: 6rem;
  }

  @media (max-width: 1400px) {
    margin-top: 5rem;
  }
`;

export const DivOrders = styled.div`
  width: 90%;
  max-width: 1300px;
  height: auto;
  max-height: 90%;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;
  background-color: var(--white);
  box-shadow: 0 6px 11px 0 var(--gray-750);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const DivFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  p {
    font-size: 14px;
    color: var(--gray-650);
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DivSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateInputContainer = styled.div`
  display: flex;

  p {
    font-weight: bold;
    margin-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
`;

export const DateContent = styled.div`
  div {
    display: flex;
    margin-bottom: -0.5rem;
  }

  span {
    color: var(--green);
  }
`;

export const InvalideDate = styled.p`
  color: var(--danger) !important;
  font-size: 0.9rem;
`;

export const DivSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    margin-top: 3rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DivDate = styled.div`
  margin-top: -1.25rem;
  display: flex;
  flex-direction: column;
  width: 300px;

  div {
    height: 2rem;
  }

  @media (max-width: 920px) {
    margin-top: 1.5rem;
  }
`;

export const DivStatus = styled.div`
  width: 125px;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  p {
    margin-bottom: 0.5rem;
    color: var(--gray-650);
    font-weight: bold;
  }

  @media (max-width: 1100px) {
    margin-left: 0;
    margin-top: 3rem;
  }
`;

export const SelectStatus = styled.div`
  width: 120px;
  height: 29px;
  border-radius: 20px;
  border: 1px solid var(--gray-650);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  i {
    font-size: 0.75rem;
    margin-right: 0.95rem;
    color: var(--gray-650);
  }
`;

export const Label = styled.label<LabelProps>`
  margin-left: 0.5rem;
  font-size: 0.81rem;
  font-weight: 600;
  line-height: 2.4;
  letter-spacing: -0.29px;
  color: var(--gray-650);

  input {
    margin-right: 0.25rem;
  }

  ${({ active }) =>
    active &&
    `
    color: var(--black);
    `}
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 90%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem 1rem 2.5rem 1rem;

  border-radius: 15px;
  background-color: var(--white);
`;

export const NotFound = styled.p`
  font-size: 1rem;
  color: var(--danger);
  margin: 2rem auto;

  i {
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }
`;
