import { Routes } from './routes/routes';
import {
  InvoiceSelectedContextProvider,
  SettingsSelectedContextProvider,
  OrderSelectedContextProvider
} from './contexts';

import { GlobalStyle } from './styles/GlobalStyles';

const App: React.FC = () => {
  return (
    <InvoiceSelectedContextProvider>
      <SettingsSelectedContextProvider>
        <OrderSelectedContextProvider>
          <GlobalStyle />
          <Routes />
        </OrderSelectedContextProvider>
      </SettingsSelectedContextProvider>
    </InvoiceSelectedContextProvider>
  );
};

export default App;
