import styled from 'styled-components';

export const Data = styled.div`
  width: 100%;

  p {
    color: var(--gray-700) !important;
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
  }
`;
