/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { green } from '@material-ui/core/colors';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { verifyDate } from '../../../utils/verifyDate';
import { Icon } from './styles';

interface DateProps {
  setSelectDate: Dispatch<SetStateAction<string | null>>;
  selectDate: any;
}

const defaultMaterialTheme = createTheme({
  palette: {
    primary: green
  },
  typography: {
    fontSize: 10
  },
  shape: {
    borderRadius: 12
  }
});

export const DateInput: React.FC<DateProps> = ({
  setSelectDate,
  selectDate
}) => {
  const [dateInput, setDateInput] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setDateInput(date);

    if (date) {
      const formatDate = `${verifyDate(date.getDate())}/${verifyDate(
        date.getMonth() + 1
      ).toString()}/${date.getFullYear()}`;
      setSelectDate(formatDate);
    }
  };

  useEffect(() => {
    if (!selectDate) {
      setDateInput(null);
    }
  }, [selectDate]);

  return (
    <div style={{ marginRight: '10px' }}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Icon className="far fa-calendar-alt" />
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            value={dateInput}
            onChange={handleDateChange}
            format="dd/MM/yyyy"
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};
