import React, { createContext, useContext, useEffect, useState } from 'react';

type SettingsSelectedContext = {
  selectedSettings: { company: string; id: number };
  setSettingsSelectedState(company: string, id: number): void;
};

export const SettingsSelectedContext = createContext<SettingsSelectedContext>(
  {} as SettingsSelectedContext
);

export const SettingsSelectedContextProvider: React.FC = ({ children }) => {
  const [selectedSettings, setSelectedSettings] = useState({
    id: 0,
    company: ''
  });

  const setSettingsSelectedState = (company: string, id: number) => {
    setSelectedSettings({
      ...selectedSettings,
      id,
      company
    });
  };

  return (
    <SettingsSelectedContext.Provider
      value={{
        selectedSettings,
        setSettingsSelectedState
      }}>
      {children}
    </SettingsSelectedContext.Provider>
  );
};

export function useSettingsSelected(): SettingsSelectedContext {
  const context = useContext(SettingsSelectedContext);
  return context;
}
