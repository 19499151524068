/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';

import { Divider, Spinner, TablePaginationActions } from '../..';

import {
  DivPagination,
  ContentRow,
} from './styles';

import { StyledTableCell, StyledTableRow } from '../styles';

interface TableLabelProps {
  from: any;
  to: any;
  count: any;
}

interface Data {
  quantity: number;
  product: string;
  price: number;
  total: number;
}

interface TableContentProps {
  contentPage: any;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalElements: number;
}

const Head = () => {
  const headerTable = [
    'Quantity',
    'Product',
    'Price',
    'Total'
  ]

  return (
    <>
      <TableHead>
        <TableRow>
          {
            headerTable.map((header, index) => (
              <TableCell
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="table-cell-head head"
                align="center"
                padding="none">
                {header}
              </TableCell>
            ))
          }
        </TableRow>
        <TableRow>
          <TableCell className="table-cell-divider" colSpan={4} size="small">
            <div>
              <Divider />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableOrderDetails = (props: TableContentProps) => {

  const {
    contentPage,
    page,
    setPage,
    totalElements,
  } = props;

  const [rows, setRows] = useState<Data[]>([]);
  const [rowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  function defaultLabelDisplayedRows(label: TableLabelProps) {
    const { from, to, count } = label;
    return `Show ${from}-${to} of ${count !== -1 ? count : `more than ${to}`
      } results`;
  };

  useEffect(() => {
    setRows(contentPage);
  }, [contentPage]);

  return (
    <>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <Head />
          <>
            {rows ? (
              <TableBody>
                <>
                  {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <StyledTableRow
                        hover
                        tabIndex={-1}
                      >
                        <StyledTableCell scope="row" padding="none" align="center">
                          <ContentRow>
                            <p>{row?.quantity}</p>
                          </ContentRow>
                        </StyledTableCell>
                        <StyledTableCell scope="row" padding="none" align="center">
                          <ContentRow>
                            <p>{row?.product}</p>
                          </ContentRow>
                        </StyledTableCell>
                        <StyledTableCell scope="row" padding="none" align="center">
                          <ContentRow>
                            <p>{new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR'
                            }).format(row?.price)}</p>
                          </ContentRow>
                        </StyledTableCell>
                        <StyledTableCell scope="row" padding="none" align="center">
                          <ContentRow>
                            <p>{new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR'
                            }).format(row?.total)}</p>
                          </ContentRow>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              </TableBody>
            ) : (
              <Spinner />
            )}
          </>
        </Table>
      </TableContainer>

      {!!rows && rows?.length > 5 && (
        <DivPagination>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[]}
            colSpan={3}
            count={totalElements}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={defaultLabelDisplayedRows}
            ActionsComponent={TablePaginationActions}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </DivPagination>
      )}
    </>
  );
};
