import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: relative;
`;

export const ModalContent = styled.div`
  width: 350px;
  max-width: 350px;
  height: 100px;
  padding: 0.65rem;
  margin-top: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 8;
  position: absolute;

  background-color: var(--white);
  color: var(--gray-650);
  border-radius: 0.5rem;
  box-shadow: 0 10px 11px 0 var(--shadow-20);
  border: 1px solid var(--green);

  @media (max-width: 900px) {
    width: 16rem;
    height: 10rem;
    padding: 0.5rem;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: 900px) {
    margin-top: 0.75rem;
  }
`;

export const Text = styled.p`
  font-size: 13px;
  line-height: 10px;
  text-align: center;
  padding-top: 0.75rem;
`;
