import { InputHTMLAttributes } from 'react';
import { ValidationMessage } from '../../ValidationMessage';
import { Container } from '../styles';

interface EmailInputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasIcon?: boolean;
  label: string;
  name?: string;
  emailError?: boolean;
}

export const EmailInput: React.FC<EmailInputProps> = ({
  label,
  hasIcon,
  name,
  emailError,
  ...rest
}) => {
  return (
    <>
      <Container hasIcon={hasIcon}>
        <label htmlFor={name}>{label}</label>
        {hasIcon && <i className="far fa-user" />}
        <input type="text" id={name} {...rest} required />
      </Container>
      {emailError && (
        <ValidationMessage error>Username is required</ValidationMessage>
      )}
    </>
  );
};
