/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Checkbox
} from '@mui/material';

import { history } from '../../../services/history';
import { useOrderSelected } from '../../../contexts';
import { getFilterOrder, deleteOrders } from '../../../services/orders';
import { Divider, TablePaginationActions, Spinner, ConfirmModal } from '../..';

import sortIcon from '../../../assets/sortIcon.png';

import {
  SortIcon,
  DivPagination,
  Data,
  Status,
  DeleteButton,
  ToastMessage
} from './styles';

import { StyledTableCell, StyledTableRow } from '../styles';

interface TableLabelProps {
  from: any;
  to: any;
  count: any;
}

type Order = 'asc' | 'desc';

interface Data {
  id: number;
  company: string;
  date: string;
  login: number;
  orderId: number;
  product: string;
  total: number;
  orderStatus: string;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface TableContentProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  contentPage: any;
  filtered: any;
  initial: any;
  final: any;
  search: string;
  status: string;
  totalElements: number;
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
}

const Head = (props: EnhancedTableProps) => {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            className="table-cell-head head"
            align="center"
            padding="checkbox">
            Select
          </TableCell>
          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'orderId' && order}>
            <TableSortLabel
              active={orderBy === 'orderId'}
              direction={orderBy === 'orderId' ? order : 'asc'}
              onClick={createSortHandler('orderId')}>
              Order ID
              {orderBy === 'orderId' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'customer.login' && order}>
            <TableSortLabel
              active={orderBy === 'customer.login'}
              direction={orderBy === 'customer.login' ? order : 'asc'}
              onClick={createSortHandler('customer.login')}>
              Login
              {orderBy === 'customer.login' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'customer.company' && order}>
            <TableSortLabel
              active={orderBy === 'customer.company'}
              direction={orderBy === 'customer.company' ? order : 'asc'}
              onClick={createSortHandler('customer.company')}>
              Company
              {orderBy === 'customer.company' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Total
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none"
            sortDirection={orderBy === 'date' && order}>
            <TableSortLabel
              active={orderBy === 'date'}
              direction={orderBy === 'date' ? order : 'asc'}
              onClick={createSortHandler('date')}>
              Date
              {orderBy === 'date' ? (
                <SortIcon>
                  {order === 'desc' ? (
                    <i className="fas fa-arrow-down fa-sm" />
                  ) : (
                    <i className="fas fa-arrow-up fa-sm" />
                  )}
                </SortIcon>
              ) : (
                <img alt="sort icon" src={sortIcon} />
              )}
            </TableSortLabel>
          </TableCell>

          <TableCell
            className="table-cell-head head"
            align="center"
            padding="none">
            Status
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="table-cell-divider" colSpan={7} size="small">
            <div>
              <Divider />
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const TableOrders = (props: TableContentProps) => {
  const { setOrderSelectedState } = useOrderSelected();
  let getOrdersData;
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    contentPage,
    filtered,
    initial,
    final,
    search,
    status,
    totalElements,
    order,
    setOrder,
    orderBy,
    setOrderBy
  } = props;

  const [rows, setRows] = useState<Data[]>([]);
  const [selected] = useState<readonly string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<Data[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleGetOrdersPerPage = async () => {
    getOrdersData = await getFilterOrder(
      status,
      search,
      rowsPerPage,
      initial,
      final,
      page,
      order,
      orderBy
    );

    if (getOrdersData) {
      setRows(getOrdersData.content);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function defaultLabelDisplayedRows(label: TableLabelProps) {
    const { from, to, count } = label;
    return `Show ${from}-${to} of ${
      count !== -1 ? count : `more than ${to}`
    } results`;
  }

  const handleGetOrderByID = async (
    id: number,
    orderId: number,
    company: string,
    total: string
  ) => {
    setOrderSelectedState({
      id,
      orderId,
      company,
      total
    });

    history.push(`/orders/${id}`);
  };

  const handleChangeSelected = (row: Data) => {
    if (selectedOrders.find((ord) => ord.id === row.id)) {
      return setSelectedOrders(
        selectedOrders.filter((ord) => ord.id !== row.id)
      );
    }

    return setSelectedOrders([...selectedOrders, row]);
  };

  const handleDeleteOrder = async () => {
    try {
      setShowToast(true);
      setShowDeleteModal(false);
      await deleteOrders(selectedOrders.map((ord) => ord.id));
      handleGetOrdersPerPage();

      setTimeout(() => {
        setShowToast(false);
        setSelectedOrders([]);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeContent = () => {
    return (
      <>
        {rows ? (
          <TableBody>
            <>
              {rows?.map((row) => {
                const totalOrder = new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(row.total);

                let statusSelected = row.orderStatus;
                if (statusSelected === 'WAITING') {
                  statusSelected = 'NON INVOICED';
                }

                return (
                  <StyledTableRow
                    hover={row.orderStatus !== 'DELETED'}
                    tabIndex={-1}
                    key={row.id}
                    className={`${
                      row.orderStatus === 'DELETED' && 'row-deleted-item'
                    }`}>
                    <StyledTableCell scope="row" padding="none" align="center">
                      {row.orderStatus !== 'DELETED' && (
                        <Checkbox
                          color="success"
                          onChange={() => handleChangeSelected(row)}
                          checked={
                            !!selectedOrders.find((ord) => ord.id === row.id)
                          }
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        handleGetOrderByID(
                          row.id,
                          row.orderId,
                          row.company,
                          totalOrder
                        )
                      }
                      scope="row"
                      padding="none"
                      align="center">
                      <Data isDeleted={row.orderStatus === 'DELETED'}>
                        <p>{row.orderId}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        handleGetOrderByID(
                          row.id,
                          row.orderId,
                          row.company,
                          totalOrder
                        )
                      }
                      scope="row"
                      padding="none"
                      align="center">
                      <Data isDeleted={row.orderStatus === 'DELETED'}>
                        <p>{row.login}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        handleGetOrderByID(
                          row.id,
                          row.orderId,
                          row.company,
                          totalOrder
                        )
                      }
                      scope="row"
                      padding="none"
                      align="center">
                      <Data isDeleted={row.orderStatus === 'DELETED'}>
                        <p>{row.company}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        handleGetOrderByID(
                          row.id,
                          row.orderId,
                          row.company,
                          totalOrder
                        )
                      }
                      scope="row"
                      padding="none"
                      align="center">
                      <Data isDeleted={row.orderStatus === 'DELETED'}>
                        <p>{totalOrder}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        handleGetOrderByID(
                          row.id,
                          row.orderId,
                          row.company,
                          totalOrder
                        )
                      }
                      scope="row"
                      padding="none"
                      align="center">
                      <Data isDeleted={row.orderStatus === 'DELETED'}>
                        <p>{row.date}</p>
                      </Data>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() =>
                        handleGetOrderByID(
                          row.id,
                          row.orderId,
                          row.company,
                          totalOrder
                        )
                      }
                      scope="row"
                      padding="none"
                      align="center">
                      <Status>{statusSelected}</Status>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </>
          </TableBody>
        ) : (
          <Spinner />
        )}
      </>
    );
  };

  useEffect(() => {
    if (filtered?.content.length > 0) {
      setRows(filtered?.content);
    }
  }, [filtered]);

  useEffect(() => {
    setRows(contentPage);
  }, [contentPage]);

  useEffect(() => {
    handleGetOrdersPerPage();
  }, [page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    handleChangeContent();
  }, [rows]);

  return (
    <>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <Head
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={contentPage.length}
          />

          {handleChangeContent()}
        </Table>
      </TableContainer>
      {rows?.length !== undefined && (
        <DivPagination>
          <DeleteButton
            disabled={selectedOrders.length === 0}
            onClick={() => setShowDeleteModal(true)}>
            Delete selected orders
          </DeleteButton>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 20]}
            colSpan={3}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={defaultLabelDisplayedRows}
            ActionsComponent={TablePaginationActions}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page'
              },
              native: true
            }}
          />
          {showToast && (
            <ToastMessage>
              <i className="fas fa-check-circle" />
              <p>
                {`Orders ${selectedOrders.map(
                  (item) => item.id
                )} has been deleted.`}
              </p>
            </ToastMessage>
          )}
        </DivPagination>
      )}

      <ConfirmModal
        message="Are you sure you want to delete the orders?
        All information will be deleted."
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={handleDeleteOrder}
      />
    </>
  );
};
