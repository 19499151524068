import styled from 'styled-components';

type SearchInputProps = {
  hasIcon?: boolean;
};

export const Container = styled.div<SearchInputProps>`
  position: relative;
  border-radius: 12px;
  width: fit-content;
  border-bottom: solid 2px var(--gray-90);
  margin-top: 1.65rem;

  > input {
    padding: ${(props) => (props.hasIcon ? '7px 50px' : '7px 20px')};
    border: none;
    font-size: 14px;
    color: var(--gray-700);

    &::placeholder {
      color: var(--gray-650);
    }

    &:focus {
      border-radius: 12px;
      outline: none;
      box-shadow: 0 0 0 2.4px var(--background-green);
    }
  }

  > i {
    position: absolute;
    left: 20px;
    top: 9px;
    font-weight: bold;
    color: var(--gray-600);
    font-size: 0.75rem;
  }

  @media (max-width: 780px) {
    > input {
      font-size: 0.75rem;
    }
  }
`;
