import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    html {
      font-size: 16px;
      font-family: 'Avenir', sans-serif;
      letter-spacing: -0.4px;

      @media (max-width: 1080px) {
        font-size: 93.75%;
      }

      @media (max-width: 720px) {
        font-size: 87.5%;
      }
    }

    button {
      cursor: pointer;
    }

    [disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
    :root {
      --white: #FFF;
      --black: #000;
      --gray-50:#E1E1E1;
      --gray-850: #2f2c44;
      --gray-750: #555367;
      --gray-700: #5A5773;
      --gray-650: #7A7A7F;
      --gray-600: #8F8F8F;
      --gray-100: #d9d9d9;
      --gray-90: #d8d8d8;
      --gray-20: #f0f0f0;
      --shadow-20: rgba(0, 0, 0, 0.09);
      --shadow-50: rgba(0, 0, 0, 0.14);
      --background-grey: #F3F6F3;
      --background-orange: rgba(247,178,49,0.5);
      --background-green: rgba(43,101,60,0.2);
      --background-red: rgba(247,71,39,0.2);
      --green: #2B653C;
      --green-200: #0b6837;
      --green-10: #CFFBE4;
      --orange: #F7B231;
      --danger: #d11527;
    }
    .fa, .far, .fas {
      transform: scale(1.2);
    }
`;
