import { useEffect } from 'react';

import {
  Button,
  ButtonsContainer,
  ModalContainer,
  ModalContent,
  ModalOverlay
} from './styles';

type Props = {
  message: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmModal = ({ message, open, onClose, onConfirm }: Props) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
    };
  }, [onClose]);

  if (!open) {
    return null;
  }
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalContent>
          {message}
          <ButtonsContainer>
            <Button color="green" onClick={onConfirm}>
              Delete
            </Button>
            <Button color="white" onClick={onClose}>
              Cancel
            </Button>
          </ButtonsContainer>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};
