import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  gap: 28px;
  padding: 0 48px 0 70px;
`;

export const ContainerHeader = styled.section`
  display: flex;
  align-items: center;
  gap: 24px;

  h1 {
    font-size: 25px;
    font-weight: 500;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid var(--green-200);
  padding: 0 20px;
`;

export const UploadButton = styled.button`
  border: 0;
  background: transparent;

  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px 20px;

  font-family: Avenir;
  font-weight: 500;
  font-size: 15px;

  border-radius: 10px 10px 0 0;

  transition: background 0.2s;

  &:hover {
    background: var(--gray-50);
  }
`;

export const FilesUploadList = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  gap: 27px;
  padding: 13px 30px;
`;

export const FileUploaded = styled.li`
  display: flex;
  gap: 13px;
  color: var(--green-200);

  img {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const SendFilesButton = styled.button`
  font-family: Avenir;
  font-weight: 500;
  justify-content: center;

  display: flex;
  align-items: center;
  align-self: center;

  padding: 15px;
  width: 30%;

  background-color: var(--green-200);
  color: var(--white);

  border: 0;
  border-radius: 10px;

  &:hover {
    filter: brightness(0.9);
  }
`;
