/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, SetStateAction } from 'react';

import { ModalContainer, ModalContent, Options, DivOption } from './styles';

interface ModalProps {
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  setSelectFrequency?: Dispatch<SetStateAction<string>>;
}

export const FrequencyModal: React.FC<ModalProps> = ({
  setOpenModal,
  setSelectFrequency
}) => {
  const handleSelectFrequency = (frequency: string) => {
    if (setOpenModal && setSelectFrequency) {
      setOpenModal(false);
      setSelectFrequency(frequency);
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <Options>
          <DivOption onClick={() => handleSelectFrequency('')}>All</DivOption>
          <DivOption onClick={() => handleSelectFrequency('Daily')}>
            Daily
          </DivOption>
          <DivOption onClick={() => handleSelectFrequency('Weekly')}>
            Weekly
          </DivOption>
          <DivOption onClick={() => handleSelectFrequency('Monthly')}>
            Monthly
          </DivOption>
          <DivOption onClick={() => handleSelectFrequency('Does_not_repeat')}>
            Does not repeat
          </DivOption>
        </Options>
      </ModalContent>
    </ModalContainer>
  );
};
