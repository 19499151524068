/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { green } from '@material-ui/core/colors';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

interface DateProps {
  setSelectDate?: Dispatch<SetStateAction<any>>;
  setOpenDate?: Dispatch<SetStateAction<any>>;
  openDate: boolean;
}

const defaultMaterialTheme = createTheme({
  palette: {
    primary: green
  },
  typography: {
    fontSize: 10
  }
});

export const DateInputSettings: React.FC<DateProps> = ({
  setSelectDate,
  setOpenDate,
  openDate
}) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(true);
  const [date, setDate] = useState<Date | null>(new Date());

  const handleDateChange = (event: any) => {
    setOpenDatePicker(false);
    setDate(event);

    if (setOpenDate && setSelectDate) {
      setOpenDate(false);
      setSelectDate(event);
    }
  };

  const handleClose = () => {
    if (openDate) {
      setOpenDatePicker(false);
    }
    if (setOpenDate) {
      setOpenDate(false);
    }
  };

  return (
    <div style={{ display: 'none' }}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            open={openDatePicker}
            disableToolbar
            variant="dialog"
            value={date}
            onChange={handleDateChange}
            onClose={handleClose}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};
