import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--green-200);
  border-radius: 10px;
  border: 0;
  padding: 13px 19px;

  &:hover {
    filter: brightness(0.9);
  }
`;
