import React, { useState } from 'react';
import { history } from '../../services/history';
import { SideBarItem } from './SideBarItem';
import {
  SideBarContainer,
  Logo,
  Text,
  Content,
  DivLogout,
  Logout
} from './styles';

import logo from '../../assets/logo.png';

export const SideBar = () => {
  const [invoices, setInvoices] = useState(true);
  const [orders, setOrders] = useState(false);
  const [upload, setUpload] = useState(false);
  const [go2stream, setGo2stream] = useState(false);
  const [settings, setSettings] = useState(false);

  return (
    <>
      <SideBarContainer>
        <Content>
          <Logo src={logo} alt="Fruit People" />
          <SideBarItem
            active={invoices}
            text="Invoices"
            icon="fas fa-stream"
            onClick={() => {
              history.push('/invoices');
              setInvoices(true);
              setOrders(false);
              setUpload(false);
              setGo2stream(false);
              setSettings(false);
            }}
          />
          <SideBarItem
            active={orders}
            text="Orders"
            icon="far fa-file-alt"
            onClick={() => {
              history.push('/orders');
              setInvoices(false);
              setOrders(true);
              setUpload(false);
              setGo2stream(false);
              setSettings(false);
            }}
          />
          <SideBarItem
            active={upload}
            text="Upload CSV"
            icon="fas fa-file-upload"
            onClick={() => {
              history.push('/upload');
              setInvoices(false);
              setOrders(false);
              setUpload(true);
              setGo2stream(false);
              setSettings(false);
            }}
          />
          {/* <SideBarItem
            active={go2stream}
            text="Go2stream Process"
            icon="fas fa-file-upload"
            onClick={() => {
              history.push('/go2stream/processes');
              setInvoices(false);
              setOrders(false);
              setUpload(false);
              setGo2stream(true);
              setSettings(false);
            }}
          /> */}
          <SideBarItem
            active={settings}
            text="Settings"
            icon="fas fa-cog"
            onClick={() => {
              history.push('/settings');
              setInvoices(false);
              setOrders(false);
              setUpload(false);
              setGo2stream(false);
              setSettings(true);
            }}
          />
        </Content>

        <DivLogout>
          <hr />
          <Logout
            onClick={() => {
              history.push('/');
              sessionStorage.removeItem('app-token');
            }}>
            <i className="fas fa-sign-out-alt" />
            <Text>Logout</Text>
          </Logout>
        </DivLogout>
      </SideBarContainer>
    </>
  );
};
