import { InputHTMLAttributes } from 'react';
import { ValidationMessage } from '../../ValidationMessage';
import { Container } from '../styles';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  hasIcon?: boolean;
  name?: string;
  passwordError?: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  hasIcon,
  name,
  passwordError,
  ...rest
}) => {
  return (
    <>
      <Container hasIcon={hasIcon}>
        <label htmlFor={name}>{label}</label>
        {hasIcon && <i className="far fa-unlock-alt" />}
        <input id={name} type="password" {...rest} />
      </Container>
      {passwordError && (
        <ValidationMessage error>Password is required</ValidationMessage>
      )}
    </>
  );
};
