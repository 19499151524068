/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ButtonSmall, DateInputSettings, DateInputSettingsSmall } from '../..';
import { verifyDate } from '../../../utils/verifyDate';

import {
  ModalContainer,
  ModalContent,
  Label,
  Options,
  DivButton,
  DivPeriod,
  DoesNotRepeat,
  DivDate,
  DateInputContainer,
  DateContent,
  DivMonth,
  Title
} from './styles';

interface ModalProps {
  setOpenPickDate?: Dispatch<SetStateAction<boolean | undefined>>;
  setDateTime?: Dispatch<SetStateAction<any>>;
  dateTime?: SetStateAction<any>;
  invalidDate: SetStateAction<boolean>;
  setInvalidDate?: Dispatch<SetStateAction<boolean>>;
}

export const PickDateModal: React.FC<ModalProps> = ({
  setOpenPickDate,
  setDateTime,
  dateTime,
  invalidDate,
  setInvalidDate
}) => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [openPeriod, setOpenPeriod] = useState(false);
  const [openFrequency, setOpenFrequency] = useState(false);
  const [openDoesNotRepeat, setOpenDoesNotRepeat] = useState(false);
  const [period, setPeriod] = useState('');
  const [frequency, setFrequency] = useState('');
  const [date, setDate] = useState<Date | any>(null);
  const [monthOnly, setMonthOnly] = useState(false);
  const [initialDate, setInitialDate] = useState<string | null>(null);
  const [finalDate, setFinalDate] = useState<string | null>(null);

  const handleSaveConfig = () => {
    if (setOpenPickDate && setDateTime) {
      setOpenPickDate(false);
    }
  };

  const handleChangeFrequencyOption = () => {
    return (
      <>
        {openDoesNotRepeat && (
          <DoesNotRepeat>
            <DivDate>
              <Title>Select date </Title>

              <DateInputContainer>
                <DateContent>
                  <p />
                  <DateInputSettingsSmall
                    selectDate={dateTime.initialDate}
                    setSelectDate={setInitialDate}
                  />
                </DateContent>
                <DateContent>
                  <p> {invalidDate && 'Invalid Date!'}</p>
                  <DateInputSettingsSmall
                    selectDate={dateTime.finalDate}
                    setSelectDate={setFinalDate}
                  />
                </DateContent>
              </DateInputContainer>
            </DivDate>
            <DivMonth>
              <input
                type="radio"
                checked={monthOnly}
                onClick={() => {
                  if (monthOnly) {
                    setMonthOnly(false);
                  } else {
                    setMonthOnly(true);
                  }
                }}
              />
              <p>This month only</p>
            </DivMonth>
          </DoesNotRepeat>
        )}
      </>
    );
  };

  useEffect(() => {
    if (date && setDateTime) {
      const formatDate = `${verifyDate(date.getDate())}/${verifyDate(
        date.getMonth() + 1
      ).toString()}/${date.getFullYear()}`;

      setDateTime({
        ...dateTime,
        date: formatDate
      });
    }
  }, [date]);

  useEffect(() => {
    if (frequency && setDateTime) {
      setDateTime({
        ...dateTime,
        frequency
      });
    }

    if (frequency.toLocaleUpperCase() === 'DOES_NOT_REPEAT') {
      setOpenDoesNotRepeat(true);
      if (dateTime.initialDate > dateTime.finalDate && setInvalidDate) {
        setInvalidDate(true);
      }
    } else {
      setOpenDoesNotRepeat(false);
      if (setInvalidDate) {
        setInvalidDate(false);
      }
    }
  }, [frequency]);

  useEffect(() => {
    if (initialDate) {
      if (setDateTime) {
        setDateTime({
          ...dateTime,
          initialDate
        });
      }
    }

    if (finalDate) {
      if (setDateTime) {
        setDateTime({
          ...dateTime,
          finalDate
        });
      }
    }

    if (initialDate && finalDate) {
      const day1 = parseInt(initialDate?.substring(0, 2), 10);
      const month1 = parseInt(initialDate?.substring(3, 5), 10) - 1;
      const year1 = parseInt(initialDate?.substring(6, 10), 10);

      const day2 = parseInt(finalDate?.substring(0, 2), 10);
      const month2 = parseInt(finalDate?.substring(3, 5), 10) - 1;
      const year2 = parseInt(finalDate?.substring(6, 10), 10);

      const firtsDate = new Date(year1, month1, day1);
      const secondDate = new Date(year2, month2, day2);

      if (
        (secondDate.getTime() < firtsDate.getTime() ||
          finalDate === initialDate) &&
        setInvalidDate
      ) {
        setInvalidDate(true);
        if (setDateTime) {
          setDateTime({
            ...dateTime,
            finalDate
          });
        }
      } else if (setInvalidDate) {
        setInvalidDate(false);

        if (setDateTime) {
          setDateTime({
            ...dateTime,
            initialDate,
            finalDate
          });
        }
      }
    }
  }, [initialDate, finalDate]);

  useEffect(() => {
    if (period && setDateTime) {
      setDateTime({
        ...dateTime,
        period
      });
    }
  }, [period]);

  useEffect(() => {
    if (monthOnly && setDateTime) {
      setDateTime({
        ...dateTime,
        repeat: true
      });
    } else if (setDateTime) {
      setDateTime({
        ...dateTime,
        repeat: false
      });
    }
  }, [monthOnly]);

  useEffect(() => {
    handleChangeFrequencyOption();
  }, [openDoesNotRepeat]);

  useEffect(() => {
    setFrequency(dateTime.frequency);
    setInitialDate(dateTime.initialDate);
    setFinalDate(dateTime.finalDate);
    setMonthOnly(dateTime.repeat);
  }, []);

  return (
    <ModalContainer>
      <ModalContent>
        <Options>
          <div onClick={() => setOpenDate(true)}>
            <Label active={!!dateTime.date}>
              {dateTime.date || 'Start Date'}
            </Label>
            <i className="fas fa-chevron-down" />
          </div>
          {openDate && (
            <DateInputSettings
              setSelectDate={setDate}
              setOpenDate={setOpenDate}
              openDate={openDate}
            />
          )}

          <div onClick={() => setOpenPeriod(!openPeriod)}>
            <Label active={!!dateTime.period}>
              {dateTime.period || 'Period'}
            </Label>
            <i className="fas fa-chevron-down" />
          </div>
          {openPeriod && (
            <DivPeriod>
              <div>
                <p
                  onClick={() => {
                    setPeriod('Morning');
                    setOpenPeriod(false);
                  }}>
                  Morning
                </p>
                <p
                  onClick={() => {
                    setPeriod('Afternoon');
                    setOpenPeriod(false);
                  }}>
                  Afternoon
                </p>
                <p
                  onClick={() => {
                    setPeriod('Evening');
                    setOpenPeriod(false);
                  }}>
                  Evening
                </p>
              </div>
            </DivPeriod>
          )}
          <div onClick={() => setOpenFrequency(!openFrequency)}>
            <Label active={!!dateTime.frequency}>
              {dateTime.frequency || 'Frequency'}
            </Label>
            <i className="fas fa-chevron-down" />
          </div>
          {openFrequency && (
            <DivPeriod>
              <div>
                <p
                  onClick={() => {
                    setFrequency('Does_not_repeat');
                    setOpenFrequency(false);
                  }}>
                  Does not repeat
                </p>
                <p
                  onClick={() => {
                    setFrequency('Daily');
                    setOpenFrequency(false);
                  }}>
                  Daily
                </p>
                <p
                  onClick={() => {
                    setFrequency('Weekly');
                    setOpenFrequency(false);
                  }}>
                  Weekly
                </p>
                <p
                  onClick={() => {
                    setFrequency('Monthly');
                    setOpenFrequency(false);
                  }}>
                  Monthly
                </p>
              </div>
            </DivPeriod>
          )}
        </Options>
        {handleChangeFrequencyOption()}
        <DivButton>
          <ButtonSmall
            active={
              dateTime.date.length > 0 &&
              dateTime.frequency.length > 0 &&
              dateTime.period.length > 0 &&
              !invalidDate
            }
            onClick={handleSaveConfig}>
            Save
          </ButtonSmall>
        </DivButton>
      </ModalContent>
    </ModalContainer>
  );
};
