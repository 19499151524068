import styled from 'styled-components';

type DataProps = {
  isDeleted?: boolean;
};

export const SortIcon = styled.div`
  margin-left: 0.4rem;
`;

export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const Data = styled.div<DataProps>`
  width: 100%;

  p {
    color: ${({ isDeleted }) =>
      isDeleted ? 'var(--gray-600)' : 'var(--gray-700) !important'};
    font-size: 0.8125rem !important;
    font-weight: 600 !important;
  }
`;

export const Status = styled.div`
  margin: 0 auto;
  width: 110px;
  height: 27px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  color: var(--white);
  font-size: 0.75rem;
`;

export const DeleteButton = styled.button`
  border-radius: 20px;
  background-color: var(--white);
  color: var(--green);
  border: 1px solid var(--green-200);

  padding: 15px;
  font-size: 0.8125rem;
  white-space: nowrap;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--green-200);
    color: var(--white);
  }
`;

export const ToastMessage = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 5rem;

  width: 350px;

  display: flex;
  align-items: stretch;
  gap: 18px;
  padding: 12px 24px;
  border-radius: 1.25rem;

  background-color: var(--green-10);
  color: var(--green-200);

  font-size: 13px;
  font-weight: 600;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
