import styled from 'styled-components';

type InputProps = {
  active?: boolean;
};

export const Container = styled.div<InputProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: 1rem 0;
  color: var(--black);
  border-bottom: 3px solid var(--gray-100);

  span {
    color: var(--gray-850);
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.67;
    text-align: left;
    margin-left: 0.5rem;
  }

  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid var(--green);
  `}
`;

export const LabelFile = styled.label`
  color: var(--ccm-blue);
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 0.55;
  text-align: left;
  cursor: pointer;
  padding: 0.75rem 3rem;
  border: 1px solid var(--gray-100);
  border-radius: 0.5rem 0.5rem 0 0;

  &:hover {
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: var(--gray-20);
  }

  i {
    font-size: 0.85rem;
    margin-right: 1rem;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const FileName = styled.div`
  color: var(--green);
  font-size: 1rem;
  font-weight: 700;
  line-height: 0.67;
  text-align: left;
  padding-left: 3rem;
  display: flex;
  align-items: center;

  i {
    color: var(--danger);
    margin-left: 0.75rem;
    font-size: 0.85rem;
    cursor: pointer;
  }
`;
